@import '../../Styles/settings';

@keyframes fade-in-fwd {
  0% {
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

.fullWidth{
  width: 100%;
}

.fullHeight{
  height: 100%;
}

.imgFit {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* for ie 11*/
  flex-shrink: 0;
}

.placeHolder {
  width: 100%;
  height: 100%;
  background: no-repeat center url('/dist/img/icons/img-placeholder.svg');
  background-size: contain;
}

.img {
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.span {
  display: inline-block;
  height: 1px;
  width: 1px;
}

.circle{
  border-radius: 100%;
}
