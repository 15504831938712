@import '../../Styles/settings';

$i: 1;
$y: 1;

.root {
  &.useShadow {
    filter: drop-shadow(0px 4px 20px rgba(95, 99, 104, 0.08));
  }
}

.col-1\/1 {
  grid-column-end: span 4;
}

@for $i from 1 through 6 {
  @for $y from 1 through 6 {
    @if ($i / $y) < 1 {
      .col-#{$i}\/#{$y} {
        grid-column-end: span 4;
      }
    }
  }
}

@media #{$sm-only} {
  .col-1\/1 {
    grid-column-end: span 6;
  }

  .col-1\/2 {
    grid-column-end: span 3;
  }

  .col-1\/3 {
    grid-column-end: span 3;
  }

  .thirdWidthInTablet {
    grid-column-end: span 2;
  }

  .col-1\/4 {
    grid-column-end: span 3;
  }

  .col-3\/4 {
    grid-column-end: span 3;
  }
}

@media #{$md-up} {
  .col-1\/1 {
    grid-column-end: span 12;
  }

  .col-1\/2 {
    grid-column-end: span 6;
  }

  .col-1\/3 {
    grid-column-end: span 4;
  }

  .col-1\/4 {
    grid-column-end: span 3;
  }

  .col-2\/3 {
    grid-column-end: span 8;
  }

  .col-3\/4 {
    grid-column-end: span 9;
  }
}

.root {
  &.noSidePadding {
    padding-right: 0;
    padding-left: 0;
  }
}

.gutter-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.relative {
  position: relative;
}

:global(.slider) {
  .root {
    padding-left: 0;
    padding-right: 0;
  }
}

@media #{$xs-only} {
  .contentAreaContext {
    grid-column-end: span 4;
  }
}
