@import '../../../Styles/settings';

.root {
  padding: 0 $gutterContentArea;
}

.zooja {
  font-family: 'ZoojaLight';
}

@media #{$sm-up} {
  .root {
    padding: 0;
  }
}