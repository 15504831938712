@import '../../../Styles/settings';

.root {
  align-self: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 16px;
  margin-bottom: 0;
  position: relative;
  flex-wrap: wrap;
  flex: 1.5;
}

@include media(600px) {
  .root {
    align-items: center;
  }
}

@media #{$md-up} {
  .root {
    padding-left: 16px;
    padding-right: 24px;
    margin-bottom: 0;
  }
}
