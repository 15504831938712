@import '../../Styles/settings';

.wrapper {
  padding: $verticleBlockPaddingXS 0;
  margin: auto;
  max-width: 100%;
  flex: 0 0 100%;
}
.bodyText {
  width: 100%;
}

.zooja {
  @include zoojaFont;
}

.linkContainer {
  padding-top: 25px;
}

.linkWrapper {
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

@media #{$md-up} {
  .wrapper {
    padding: $verticleBlockPaddingMD 0;
  }
}

.noVerticalPadding {
  padding-bottom: 0;
  padding-top: 0;
}

