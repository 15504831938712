@import '../../Styles/settings';

.spinner {
  display: initial;
  left: 47%;
  width: 25px;
  height: 25px;
  animation: rotate 2s linear infinite;

  &--path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  &--white {
    stroke: $white;
  }
}

@keyframes circle {
  0% {
    stroke-dashoffset: calc(3.14 * 2 * 100%);
  }
  25% {
    stroke-dashoffset: calc(3.14 * 1 * 100%);
  }
  50% {
    stroke-dashoffset: 0%;
  }
  75% {
    stroke-dashoffset: -calc(3.14 * 1 * 100%);
  }
  100% {
    stroke-dashoffset: -calc(3.14 * 2 * 100%);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke: $color-brand;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
