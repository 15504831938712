@import '../../../Styles/settings';

.root {
  padding: 32px $gutterContentArea 0px;

  &.homeowner{
    background: $color-bona-homeowner;
  }

  &:not(.homeowner){
    background: $color-bona-professional;
  }

  &.blueBackground {
    background: $clear-blue;
  }

  &.noBackground {
    background: transparent;
  }
}

.rootWrapper {
  margin: 0 auto;
  max-width: $content-container-width;
  width: 100%;
  padding: 0 0 40px;
}

.relatedItemsHeader {
  @include HelveticaMedium;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0px;
}

.carouselGridItem {
  padding-bottom: 40px;
  margin-bottom: 15px;
}

.productCard-mobile-1 {
  grid-column-end: span 4;
}

.productCard-mobile-1,
.productCard-mobile-2,
.productCard-mobile-4,
.productCard-tablet-2,
.productCard-tablet-3,
.productCard-tablet-6,
.productCard-desktop-2,
.productCard-desktop-3,
.productCard-desktop-4,
.productCard-desktop-6 {
  display: flex;
}

.productCard-mobile-2 {
  grid-column-end: span 2;
}

.productCard-mobile-4 {
  grid-column-end: span 1;
}

.productCard-tablet-2 {
  grid-column-end: span 3;
}

.productCard-tablet-3 {
  grid-column-end: span 2;
}

.productCard-tablet-6 {
  grid-column-end: span 1;
}

.productCard-desktop-2 {
  grid-column-end: span 6;
}

.productCard-desktop-3 {
  grid-column-end: span 4;
}

.productCard-desktop-4 {
  grid-column-end: span 3;
}

.productCard-desktop-6 {
  grid-column-end: span 2;
}

.leftArrow {
  left: -36px;
}

.rightArrow {
  right: -36px;
}

@media #{$sm-up} {
  .productCardsWrapper {
    display: 'grid';
    height: 100%;
  }

  .relatedVariants {
    padding: 0 80px 40px 80px;
  }
}

@media #{$md-up} {
  .root {
    padding: 56px $gutterContentArea 0px;
  }

  .rootWrapper {
    padding: 0 0 60px;
  }
  .relatedItemsHeader {
    margin-bottom: 10px;
  }

  .carouselGridItem {
    margin-bottom: 0;
    padding-top: 14px;
  }
}
