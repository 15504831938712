@import '../../Styles/settings';

.container {
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-top: 112px;
  padding-bottom: 64px;
}

.form {
  display: flex;
  flex-direction: column;
}

.rightColumn {
  z-index: 1;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.infoText {
  font-size: 16px;
  line-height: 20px;
}

.informations {
  margin-top: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.infoContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.footerBlockContainer {
  background-color: $bona-light-blue;
}

.signupSection {
  margin-top: 32px;
}

.infoAreaContainer {
  margin-top: 8px;
}

.redirectButtonContainer{
  display: flex;
}

.heading {
  @include HelveticaMedium;
  font-size: 36px;
  line-height: 40px;
  color: $midnight-blue;
  z-index: inherit;
}

@media #{$md-up} {
  .formInput {
    flex-basis: 70%;
    margin-right: 16px;
  }

  .formSubmit {
    flex-basis: 30%;
    display: flex;
  }

  .form {
    flex-direction: row;
    height: 48px;
  }

  .signupSection {
    margin-top: 48px;
  }

  .heading {
    font-size: 60px;
    line-height: 64px;
  }

  .container {
    flex-direction: row;
    padding-top: 42px;
    padding-bottom: 42px;
    column-gap: 120px;
  }

  .informations {
    margin-top: 32px;
  }
}
