.colorBlendProductCard {
    background-color: var(--color-primary);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: white;
    gap: 16px;
    text-align: center;
    flex: 1;
    border-radius: 8px;
    border: solid 2px white;
    cursor: pointer;
    &.selected {
        border: solid 2px #004fe0;
    }
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
    }
}

.colorBlendProductImage {
    object-fit: contain;
}
