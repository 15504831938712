.nba {
    position: relative;
    .nbaContent {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        padding: 32px;
        width: 272px;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
}
