@import '../../Styles/settings';

.FooterSiteInfo {
  border-top: 1px solid $midnight_border;
  padding-bottom: 7px;
  
  > div {
    padding: $gutterBottomSM 0;
    display: flex;
    flex-wrap: wrap-reverse;
  }
}

.logo {
  display: none;
}

.centerAlign { 
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 27px;
}

.svgPayment {
  margin: 0 10px;
}

.rightAlign, .leftAlign {
  padding: $gutterBottomSM;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.rightAlign {
  img {
    margin-right: unset;
    margin-left: 24px;
  }

  a {
    display: flex;
    justify-content: center;
  }

  svg {
    margin: 0 8px;
  }
}

.leftAlign {
  font-size: 10px;

  a {
    text-decoration: none;
    margin: 0 8px;
  }

  p {
    margin: 0 8px;
  }
}

@media #{$md-up} {
  .logo {
    display: inline;
    max-height: 32px;
  }

  .rightAlign {
    justify-content: end;
    padding-top: unset;
    padding-bottom: unset;

    svg {
      margin: 0 4px;
    }
  }

  .leftAlign {
    justify-content: start;
    padding-top: unset;
    padding-bottom: unset;
  }

  .FooterSiteInfo {
    border-top: unset;
  }
}
