@import '../../Styles/settings';

/**
* Override styles from FS Lightbox
*/

.container {
  padding: 48px 0px;
  :global(.fslightbox-toolbar-button[title='Enter fullscreen']) {
    display: none;
  }

  :global(.fslightbox-toolbar-button[title='Close']) {
    background: $white;

    svg {
      path {
        fill: $bona-dark-blue;
      }
    }
  }

  :global(.fslightbox-container) {
    background: $near-white;
  }

  :global(.fslightbox-slide-btn-container) {
    &:hover {
      svg {
        path {
          fill: $bona-dark-blue;
        }
      }
    }
  }

  :global(.fslightbox-slide-btn) {
    background: $white;

    svg {
      path {
        fill: $bona-dark-blue;
      }
    }
  }

  :global(.fslightbox-slide-number-container) {
    position: fixed;
    z-index: 3;
    bottom: 24px;
    left: 50%;
    height: auto;
    background: $white;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-family: $font-family;
    color: $near-black;
  }

  :global(.fslightbox-slash) {
    background: $near-black;
  }
}

.gallery {
  display: grid;
  gap: 8px;
}

.galleryOne {
  max-height: 500px;
  grid-template-rows: 1fr;
  grid-template-areas: 'first';
}

.galleryTwo {
  max-height: 500px;
  grid-template-rows: 1fr;
  grid-template-areas: 'first second second';
}

.galleryThree {
  max-height: 500px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'first third third'
    'second third third';
}

.galleryFour {
  max-height: 375px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'first second fourth fourth'
    'third second fourth fourth';
}

.galleryFive {
  max-height: 750px;
  grid-template-rows: 2fr 1fr;
  grid-template-areas:
    'first second second'
    'third fourth fifth';
}

.image {
  height: 100%;
  position: relative;
  z-index: 1;
  transition: transform 0.6s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.imageOverride {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.item {
  overflow: hidden;
}

.item1 {
  grid-area: first;
}

.item2 {
  grid-area: second;
}

.item3 {
  grid-area: third;
}

.item4 {
  grid-area: fourth;
}

.item5 {
  grid-area: fifth;
}

.viewAll {
  margin-top: 24px;
}

.buttonIcon {
  margin-right: 8px;
}

.noVerticalPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media #{$sm-up} {
  .container {
    padding: 80px 0px;
  }
}
