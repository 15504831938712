@import '../../Styles/settings';

.root {
  margin: 0 -15px;
}

.ul {
  list-style: none;
  text-align: left;
  border-top: 1px solid $midnight_border;
  padding: $gutterBottomSM;
}

li.li {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:first-of-type {
    @include HelveticaMedium;
    font-size: 18px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  &.isOpen {
    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    path {
      stroke: $white;
    }
  }
}

.a {
  font-size: 12px;
  text-decoration: none;
  color: $white;
  &:hover {
    text-decoration: underline;
  }
}

@media #{$md-up} {
  .root {
    padding: 20px 0px;
  }

  li {
    &:first-of-type {
      font-size: 16px;
    }
  }

  .ul {
    border-top: unset;
    padding: unset;
  }
}
