@import '../../Styles/settings';

.wrapper {
    padding: $verticleBlockPaddingXS 0;
  }

.heading { 
 font-weight: 300;
 margin-top: 15px;
}

.intro {
  margin-bottom: 30px;
  font-size: calc(1rem + 2px);
}

@media #{$md-up} {
    .wrapper {
      padding: $verticleBlockPaddingMD 0;
    }
  }
  
  .noVerticalPadding {
    padding-bottom: 0;
    padding-top: 0;
  }