@import '../../../Styles/settings';

.details {
  display: block;
  color: $text-color;
  font-size: 11px;
  line-height: $line-height-tight;
  font-weight: 300;
  text-decoration: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
}

@include media(600px) {
  .details {
    margin-top: 2px;
    font-size: 12px;
  }
}

@media #{$md-up} {
  :global([data-style='cards-as-list']) {
    .details {
      font-size: 14px;
      align-items: center;
      flex-basis: 40%;
    }
  }
}

@media print {
  .details {
    width: 50%;
    padding-left: 10px;
    font-size: 14px;
  }
}
