@import '../../../Styles/settings';

.root {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    flex-direction: column;
  }

.backgroundImage {
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.textContainer {
    padding-top: 40px;
    margin-top: -10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 0px 0px 8px 8px;
}

.imgContainer {
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
  }

.hover {
  &:hover {
      cursor: pointer;
  }
}

:global(.slider) {
    .root {
      padding-bottom: 60px;
    }
  }

@media #{$sm-up} {

    .backgroundImage {
        height: 120px;
    }

    .textContainer {
        padding-top: 80px;
    }

    .imgContainer {
        /*
        * using "important" because when in edit mode epi's [data-epi-property-name] injected class
        * will override the rule.
        */
        min-height: 120px !important;
      }
}