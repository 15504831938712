.colorBlendWizardNavigationDot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f8f9f9;
    color: black;
    margin-right: 10px;
    pointer-events: none;
    z-index: 1;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    font-weight: 500;
    border: solid #dee1e6 1px;
    &.current {
        background-color: #004fe0;
        color: white;
        border: solid #d8e3fa 3px;
        cursor: pointer;
        pointer-events: initial;
    }
    &.active {
        background-color: #00205c;
        color: white;
        border: solid #00205c 3px;
        cursor: pointer;
        pointer-events: initial;
    }
}

.colorBlendWizardNavigation {
    display: flex;
    padding: 0;
    background-color: transparent;
    align-items: center;
    width: 100%;
    margin: 0 auto 0 0;
    .colorBlendDotsArea {
        max-width: 160px;
        display: flex;
        padding: 0;
        position: relative;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #dee1e6;
            top: 50%;
            left: 0;
        }
    }
}

.arrowArea {
    width: 100px;

    .backLink {
        display: flex;
        gap: 4px;
        align-items: center;
        cursor: pointer;
    }
}
