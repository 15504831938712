@import '../../../Styles/settings';

.titleLink {
  text-decoration: none;
  max-width: 100%;
  flex: 0 0 100%;
}

.title {
  display: flex;
  flex-direction: column;
}

.productName {
  @include HelveticaMedium;
  font-size: 16px;
  color: $near-black;
  line-height: 20px;
}

.articleNbr {
  order: -1;
  display: inline-flex;
  align-items: center;
  padding-bottom: 4px;
}

@media #{$md-up} {
  .titleLink {
    width: 50%;
  }

  .title {
    font-size: 16px;
    margin-bottom: 0;
  }
}
