@import '../../../Styles/settings';

.documentFolderCard {
    display: flex;
    align-items: center;
    padding: 30px 20px;
    &:hover {
        cursor: pointer;
    }
}

.linkWrapper {
    display: flex;
    padding-bottom: 16px;
    &.noBottomPadding {
        padding-bottom: 0px;
    }
    &.breakWord {
        word-break: break-word;
    }
}

.hide {
    display:none;
}

.link {
    text-decoration: none;
    margin-left: 10px;
    &.borderBottom {
        border-bottom: 1px solid;
        border-color: currentColor;
    }
  }

.plusSvg {
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 3px 3px;
    &.unfolded {
        transform: rotate(45deg);
        transition: 0.2s;
     }
     &.folded {
        transform: rotate(0);
        transition: 0.2s;
     }
     
}

.documentSvg {
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 3px 3px;
}

.cardWrapper {
    background-color: $pampas;
    margin-bottom: 2px;
}

.linkContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
}

.h3 {
    margin-bottom: 0;
    margin-left: 10px;
}

.h4 {
    font-size: 16px;
}

@media #{$sm-up} {
    .h4 {
        font-size: 17px;
    }

    .linkWrapperContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .linkWrapper {
        flex: 0 0 30%;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 20px;
        &.noRightMargin {
            margin-right: 0;
        }
    }

    .link {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    } 

    .linkContainer {
        padding-bottom: 15px;
    }
}

@media #{$md-up} {
    .h4 {
        font-size: 18px;
    }

    .linkWrapper {
        flex: 0 0 31.5%;
        margin-right: 24px;
    }
}