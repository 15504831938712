@import '../../Styles/settings';

.root {
  padding: $verticleBlockPaddingXS 0;
}

.checkoutRoot {
  padding: 24px 16px;
}

@include media(500px) {
  .checkoutRoot {
    padding: 20px 15px;
  }
}

@media #{$md-up} {
  .checkoutRoot {
    padding: 48px;
  }
}

.relatedItemstStyles {
  padding: 0;

  & > div {
    padding: 0;

    & > div {
      padding: 0 0 20px;
    }
  }
}

.heading {
  @include HelveticaMedium;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0px;
  margin-bottom: 10px;
}

.linkContainer {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 15px;
}

.loadingContainer {
  height: 250px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: $verticleBlockPaddingXS 0;
}
