@import '../../../Styles/settings';

@keyframes modalOpen {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.root {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  z-index: map-get($zIndexes, 'modal');
  animation: modalOpen 0.3s $main-timing-function 0.7s forwards;
}

.loadingDotsWrapper {
  background: $color-modal-bg;
  height: 100px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 10px 10px -10px black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
