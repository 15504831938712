@import '../../Styles/settings';

$i: 1;
$y: 1;
.col-1\/1 {
  flex: 0 0 100%;
  max-width: 100%;
}

@for $i from 1 through 12 {
  @for $y from 1 through 12 {
    @if ($i / $y) < 1 {
      .col-#{$i}\/#{$y} {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

@media #{$sm-only} {
  $i: 1;
  $y: 1;
  @for $i from 2 through 6 {
    .col-1\/#{$i}.contentAreaContext {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  // for block after categoryblocks on category page
  .col-3\/4 {
    &.contentAreaContext {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

@media #{$md-up} {
  $i: 1;
  $y: 1;
  @for $i from 1 through 12 {
    @for $y from 1 through 12 {
      @if $i / $y < 1 {
        .col-#{$i}\/#{$y} {
          flex: 0 0 ($i/$y) * 100%;
          max-width: ($i/$y) * 100%;
        }
      }
    }
  }
}

.root {
  padding-right: $gutterContentArea;
  padding-left: $gutterContentArea;
  &.noSidePadding {
    padding-right: 0;
    padding-left: 0;
  }

  &.alignSelfEnd {
    align-self: flex-end;
  }
}

.gutter-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.gutter-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.gutter-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.relative {
  position: relative;
}

:global(.slider) {
  .root {
    padding-left: 0;
    padding-right: 0;
  }
}

@media #{$xs-only} {
  .contentAreaContext {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
}
