@import "../../../Styles/settings";

.priceInfoWrapper {
  flex: 0 0 100%;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
  @include styleComponent("style_id_productCardBtn_addToCart") {
    flex: 100 0 70px;
    min-width: 70px;
    height: auto;
    min-height: 40px;
  }
}

.priceWrapper {
  margin-bottom: 5px;
}
.priceUnitWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.price {
  font-weight: 500;
}

.b2cPrice {
  font-weight: 500;
  font-size: 18px;
  color: #00205c;
  font-family: "Helvetica Neue LT W05_65 Medium";
}
.unit {
  font-weight: 300;
}
.spacer {
  width: 10px;
}

.priceDetails {
  font-size: 12px;
  font-weight: 300;
  color: $text-color-tone-down;
  display: inline-block;
  margin-top: 5px;
}

.addToCartWrapper {
  width: 100%;
}

.btn {
  display: none;
}

.svg {
  margin-right: -10px;
  fill: currentColor;
  height: 20px;
  width: 20px;
}

.suggestedRetailPrice {
  margin-top: auto;
}

@include media(340px) {
  .pusher {
    flex: 200 0 calc(100% - 272px);
  }
}

@include media(600px) {
  .pusher {
    display: none;
  }
  .addToCartWrapper {
    width: 220px;
    margin-left: auto;
  }

  .priceWrapper {
    flex: 0 0 calc(100% - 200px);
    max-width: calc(100% - 200px);
    margin-bottom: 5px;
  }
}

@include media(600px) {
  .priceInfoWrapper {
    flex: 0 0 220px;
    max-width: 220px;
  }
  .priceWrapper {
    flex: 0 0 100%;
    max-width: none;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding: 0 0 5px;
  }

  .priceDetails {
    width: 100%;
    text-align: right;
  }

  .btn {
    @include btn;
    display: flex;
    white-space: nowrap;
    margin-left: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $color-brand;
    transition: background-color 0.3s $main-timing-function;

    &:hover {
      background-color: darken($color-brand, 8%);
    }
  }

  .priceUnitWrapper {
    justify-content: flex-end;
  }
}

@media #{$md-up} {
  .priceInfoWrapper {
    @include styleComponent("style_id_productCardBtn_addToCart") {
      margin-left: 1px;
    }
  }
}

@media print {
  .btn,
  .addToCartWrapper {
    display: none;
  }

  .priceInfoWrapper {
    width: auto;
    margin: 0;
  }
}
