@import '../../Styles/settings';

.container {
    position: fixed;
    z-index: 2;
    bottom: 9vh;
    right: 0;
}

.image {
    max-height: 75px
}

@media #{$md-up} {
    .image {
        max-height: 200px;
    }
}