@import "../../Styles/settings";

.root {
  position: relative;
  padding: 48px $gutterContentArea;
  margin-left: -$gutterContentArea;
  margin-right: -$gutterContentArea;
  grid-column-end: span 4;
  margin-bottom: $gutterContentArea * 2;
  background-color: $near-white;

  :global(.slider-control-bottomright) {
    transform: translateY(50%);
    margin-right: $gutterContentArea;
  }
}

.contentContainer {
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  gap: $search-box-gap;
}

.column {
  width: 49%;
  min-width: $min-width-search-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.searchContainer {
  position: relative;

  input {
    background-color: $white;
    color: $near-black !important;
    padding: 0 16px;
    border: 1px solid $near-black !important;
  }

  label {
    background-color: $white;
    color: $near-black !important;
  }
}

.title {
  @include HelveticaMedium;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 16px;
}

.bodyText p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}

.actions {
  position: absolute;
  right: 12px;
  top: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    margin-right: 4px;
    padding: 0;
  }

  & > svg {
    margin: 2px;
  }
}

.resultsContainer {
  position: absolute;
  top: 48px;
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
}

.searchItem {
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    background-color: $light-gray;
  }
}

.spinner {
  margin: 14px;
  width: 20px;
  height: 20px;
  animation: rotate 2s linear infinite;
}

.path {
  stroke: $color-brand;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@media #{$sm-up} {
  .root {
    grid-column-end: span 6;
    margin-left: -$gutterContentArea;
    margin-right: -$gutterContentArea;
    :global(.slider-control-bottomright) {
      margin-right: 0;
      transform: translateY(calc(100% + 20px));
    }
  }
}

@media #{$md-up} {
  .root {
    grid-column-end: span 12;
  }
}

@include media($content-container-width) {
  .root {
    width: 100vw;
    margin-left: calc((#{$content-container-width} - 100vw) / 2);
    margin-right: calc((#{$content-container-width} - 100vw) / 2);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke: $color-brand;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
