@import "../../../Styles/settings";

@keyframes errorContainer {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}

.qtyUomContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.uomContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex-shrink: 0;
}

.title {
  @include HelveticaMedium;
  font-size: 16px;
  line-height: 20px;
  color: $near-black;
}

.root {
  display: flex;
  gap: 8px;
  width: 100%;
  position: relative;

  &.loading {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(white, 0.8);
    }
  }
}

.errorContainer {
  animation: errorContainer 2.5s $main-timing-function forwards;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 0;
  padding: 10px;
  background: $color-error;
  border-radius: 5px;
  box-shadow:
    0 1.1px 2.2px rgba($color-error, 0.02),
    0 2.7px 5.3px rgba($color-error, 0.028),
    0 5px 10px rgba($color-error, 0.035),
    0 8.9px 17.9px rgba($color-error, 0.042),
    0 16.7px 33.4px rgba($color-error, 0.05),
    0 40px 80px rgba($color-error, 0.07);

  &:after {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: $color-error transparent transparent transparent;
  }
}

.errorText {
  font-size: 14px;
  color: white;
}

.loadingDotsWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.qtyControlWrapper {
  position: relative;
  display: flex;
  width: 72px;
  align-items: center;

  input {
    background: white;
    border: 1px solid #babcbe;
    border-radius: 4px;
    padding: 13px 12px 13px 16px;
    text-align: left;
  }

  input:focus {
    outline: 1px solid $bona-border-grey;
  }
}

.qtyAdjustmentButtonsContainer {
  position: absolute;
  right: 8px;

  display: flex;
  flex-direction: column;
}

.size-m {
  height: 56px;
}

.size-s {
  height: 48px;
}

.size-xs {
  height: 40px;
}

@media #{$md-up} {
  .qtyUomContainer {
    gap: 40px;
  }
}

@media (max-width: 991px) {
  .qtyControlWrapper {
    width: 48px;

    input {
      text-align: center;
      padding: 10px 8px;
    }
  }
}
