@import '../../Styles/settings';

.retailerBlockContainer {
  width: 72px;
  height: 64px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF; 
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
  border-radius: 4px;
  padding: 8px;
}

.imgContainer {
  display: flex;
  max-height: 48px;
}

.img {
  width: 100%;
  object-fit: contain;
}
