@import "../../Styles/settings";

.accordion-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 16px;
  cursor: pointer;
  &--border {
    border-bottom: 1px solid $bona-light-grey;
  }

  &--expanded {
    border-bottom: 1px solid transparent;
  }

  &--heading {
    font-size: 16px;
    @include HelveticaMedium;
    color: $color-brand;
  }

  &--list {
    padding: 0;
  }

  &--list-heading {
    font-size: 18px;
  }
}
