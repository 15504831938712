.downloadLink {
    display: flex;
    height: 48px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-decoration: none;
    font-weight: 600;
    color: #202124;
}
