@import '../../Styles/settings';

.slideDownOuter {
  height: 0;
  overflow-y: hidden;
  transition: height 0.3s $main-timing-function;
}

.slideDownInner {
  display: block;
}

.slideDownExpanded {
  height: auto;
}

.root {
  display: block;
  padding: 8px 16px;
  color: white;
  border-radius: 4px;
}

.msg {
  font-weight: 400;
  margin-bottom: 0;
}

.success {
  background: $color-success;
  color: $near-black;
}

.error {
  background: $color-error-bg;
  color: $color-error-txt;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    path {
      stroke: $color-error-txt;
    }
  }
}
