@import '../../Styles/settings';

.root {
  padding: $verticleBlockPaddingXS 0;
  max-width: 100%;
  flex: 0 0 100%;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.linkContainer {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 15px;
}

.heading {
  font-weight: 300;
}

.loadingContainer {
  height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
}

@include media(350px) {
  .root {
    @include styleComponent('style_id_ItemListingBlock_ProductCard') {
    }
  }
}

@include media(800px) {
  .root {
    @include styleComponent('style_id_ItemListingBlock_ProductCard') {
      grid-column-end: span 3;
    }
  }
}

@media #{$md-up}{
  .root {
    padding: $verticleBlockPaddingMD 0;
  }

  .linkContainer {
    padding-right: 0;
  }
}