@import "../../Styles/settings";

.root {
  padding: 0 10px;
  margin-bottom: -100%;
  &.noBottomMargin {
    margin-bottom: 0;
  }
}

.ol {
  display: block;
  list-style: none;
  margin: 0;
  position: relative;
  padding: 10px 0 0 0;
  &.minusTop {
    top: -10px;
  }
}

.li {
  display: inline-block;
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0;
  }
}

.liBtn {
  width: 8px;
  height: 8px;
  border: 1px solid $text-color;
  border-radius: 50%;
  padding: 0;
  background: transparent;
}

.liBtnActive-black {
  background: $text-color;
}

.liBtnActive-primary {
  background: $midnight-blue;
}

.liBtnActiveWhite {
  background: white;
}

.whiteButton {
  border: 1px solid white;
}

@media #{$sm-up} {
  .ol {
    padding-top: 15px;
  }

  .liBtn {
    width: 10px;
    height: 10px;
  }
}
