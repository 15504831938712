@import '../../Styles/settings';

.root {
  display: flex;
  &.isListView{
    column-gap: 32px;
  }
  &:not(.isListView){
    column-gap: 12px;
  }
}

.btnContentUpperText {
  @include HelveticaMedium;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
}

.btnContentLowerText {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  text-transform: lowercase;
}

.clickable {
  cursor: pointer;
}

.btn {
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;
  padding-right: 24px;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    right: 0;
    top: 50%;
    height: 14px;
    width: 14px;
    transform: translate(0%, -50%);
    background-image: url(/Dist/img/icons/arrow-down-tundora.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: transform 0.3s $main-timing-function;
  }
  &:disabled {
    color: inherit;
    padding-right: 0;
    cursor: auto;
    &:after {
      background-image: none;
    }
  }
}

.btnOpen {
  &:after {
    transform: translate(0%, -50%) rotate(180deg);
  }
}

.priceInfoWrapper {
  transition: opacity 0.3s $main-timing-function;
}

.priceInfoWrapperLoading {
  opacity: 0;
}

.optionsContainer {
  position: absolute;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background: white;
  min-width: 100%;
  padding: 15px 10px;
  z-index: 1;
  top: 100%;
  list-style: none;
}

.inputContainer {
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.label {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.input {
  opacity: 0;
  position: absolute;
}

.span {
  margin-right: 10px;
}

.circle {
  width: 18px;
  height: 18px;
  border-radius: 1px;
  border: solid 1px $text-color;
  border-radius: 100%;
  pointer-events: none;
  transition: opacity 0.3s $main-timing-function;
  position: relative;
  margin-left: auto;
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    background: $text-color;
    left: 2px;
    top: 2px;
    transform: scale(0);
    border-radius: 100%;
    transition: transform 0.3s $main-timing-function;
  }
}

.input:focus + .span {
  font-weight: 500;
  & + .circle {
    &:after {
      transform: scale(0.5);
    }
  }
}

.input:checked + .span + .circle {
  &:after {
    transform: scale(1);
  }
}

@media #{$md-up} {
  .root{
    &.isListView{
      column-gap: 24px;
    }
    &:not(.isListView){
      column-gap: 24px;
    }
  }

  :global([data-style='cards-as-list']) {
    .btn {
      text-align: right;
    }
  }

  .input:hover + .span {
    font-weight: 500;
  }

  .input:hover:not(:checked) + .span {
    & + .circle {
      &:after {
        transform: scale(0.5);
      }
    }
  }
}
