.colorBlendSelectTypeRow {
    background-color: white;
    text-align: left;
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 16px;
    border: solid 2px white;
    border-radius: 4px;
    &.selected {
        border-color: #004fe0;
    }
}

.colorBlendSelectRadio {
    display: none;
}
