@import '../../Styles/settings';

.span {
  padding-bottom: 3px;
  border-bottom: 1px solid;
  border-color: currentColor;
  word-break: break-word;
}

@media #{$md-up} {
  a:hover,
  button:hover {
    .span {
      padding-bottom: 2px;
      border-width: 2px;
    }
  }
}
