.imgFit {
  display: block;
  max-width: 100%;
  height: auto;
}

.zooja {
  font-family: 'ZoojaLight';
}

.floatRight {
  float: right;
  margin-left: 20px;
}

.floatLeft {
  float: left;
  margin-right: 20px;
}
