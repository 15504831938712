@import '../../Styles/settings';

.mainWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rightColumnWrapper {
  padding-bottom: 70px;
  margin: 0 16px;
}

.bodyText {
  width: 100%;
  color: $bona-dark-blue;
  font-size: 16px;
  line-height: 20px;
}

.linkWrapper {
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.imgWrapper {
  bottom: 20px;
  position: relative;
  margin: 0 16px;
  flex-shrink: 0;
}

.linkContainer {
  margin-top: 20px;
}

.h2 {
  @include HelveticaMedium;
  font-size: 36px;
  line-height: 40px;
  color: $bona-dark-blue;
}

@media #{$md-up} {
  .mainWrapper {
    flex-direction: row;
  }

  .imgWrapper {
    bottom: 80px;
    position: relative;
    margin-left: 16px;
    flex-shrink: 0;
  }

  .rightColumnWrapper {
    margin: 48px 96px;
    max-width: 552px;
  }
}
