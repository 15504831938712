@import "../../Styles/settings";

.slideGrid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 16px;
    row-gap: 16px;
}

@media #{$sm-up} {
    .slideGrid {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}

@media #{$md-up} {
    .slideGrid {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
}
