@import '../../Styles/settings';
@keyframes svgAnimation {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(6px);
  }

  100% {
    transform: translateX(0px);
  }
}

.link {
  @include HelveticaMedium;
  border: none;
  align-items: center;
  background: transparent;
  font-size: inherit;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
}

.fullWidth{
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.black {
  color: $text-color;
}

.primary {
  color: $midnight-blue;
}

.link-white {
  color: #fff;
}

.link-dark {
  color: $tundora;
}

.link-black {
  color: $black;
}

.link-primary {
  color: $midnight-blue;
}

.svg {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

.svg-primary {
  stroke: $midnight-blue;
}

.svg-black {
  stroke: $black;
}

.svg-white {
  stroke: $white;
}

.svg-dark {
  stroke: $tundora;
}

@media #{$md-up} {
  .link {
    &:hover {
      svg {
        animation: svgAnimation $main-timing-function 0.8s;
      }
    }
  }
}
