@import "../../Styles/settings";

.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px 0px;
}

.informationBox {
  padding: 24px;
  border-radius: 8px;
  background-color: $near-white;
  width: 100%;
  height: fit-content;
}

.heading {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: $near-black;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  border-top: 1px solid $light-cyan;
  font-family: $font-family;
}

.attribute {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.value {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
}

.noVerticalPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media #{$md-up} {
  .container {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 80px;
    padding: 80px 0px;
    display: grid;
  }
}
