@import "../../Styles/settings";

.faqBlock {
    padding: $verticleBlockPaddingXS 0;
    max-width: 770px;
    margin: auto;
}

.faqBlockHeader {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    border-bottom: solid 1px #dee1e6;
    width: 100%;
    padding-bottom: 16px;
}
