@import "../../../Styles/settings";

.qtyInput {
  @include HelveticaMedium;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin-left: 1px;
  background-color: white;
  flex-basis: 100%;
  padding: 9px 0px;
  text-align: center;
  border: none;
  /* Firefox */
  -moz-appearance: textfield;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.size-s {
  font-size: 14px;
  line-height: 16px;
}

.size-m {
  font-size: 16px;
  line-height: 20px;
}
