@import '../../../Styles/settings';

.slash {
  padding: 0 3px;
}

.subheaderOneLiner {
  font-size: 12px;
  line-height: 16px;
  color: $dark-gray;
  margin-bottom: 4px;
}

@media #{$md-up} {
  .subheaderOneLiner {
    font-size: 14px;
  }
}

:global([data-style='cards-as-list']) {
  .subheaderOneLiner {
    margin-bottom: 0px;
  }
}

:global([data-style='cards-as-grid']) {
  .subheaderOneLiner {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 152px;
  }

  @media #{$md-up} {
    .subheaderOneLiner {
      max-width: 232px;
    }
  }
}
