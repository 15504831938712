@import '../../../Styles/settings';

@keyframes svgAnimation {
  0% {
    top: -1px;
  }
  100% {
    top: 7px;
  }
}

.iconWrapper {
  position: relative;
  display: flex;
  margin-right: 8px;
}

.price {
  margin: 0px 8px;
  @include HelveticaMedium;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  white-space: nowrap;
  &.smallPrice {
    margin: 0px;
  }
}

.dot {
  position: absolute;
  height: 3px;
  width: 3px;
  background: white;
  border-radius: 50%;
  top: -1px;
  left: 50%;
  &.animation {
    animation: svgAnimation $main-timing-function 0.3s;
    animation-fill-mode: forwards;
  }
}

@media #{$md-up} {
  .price {
    font-size: 14px;
    line-height: 16px;

    &.smallPrice {
      font-size: 12px;
    }
  }
}
