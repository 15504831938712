@import '../../../Styles/settings';


@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 0.7;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  .root {
    position: fixed;
    top: $mobile-header-height;
    width: 100%;
    display: flex;
    bottom: 0;
    overflow: hidden;
    right: 0;
    transform: translate3d(0, 0, 0);
    z-index: map-get($zIndexes, 'modal');
  
    &.fadeOut {
      pointer-events: none;
    }
  }
  
  .overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: map-get($zIndexes, 'modal');
    transition: opacity 0.3s ease-in;
    animation: fadeIn 0.2s ease-in;
    opacity: 0.4;
    background: $near-black;
    top: 0;
    left: 0;
    &.fadeOut {
      opacity: 0;
      pointer-events: none;
    }
  }
  
  .panel {  
    background-color: $bona-light-blue;
    margin: auto auto 0;
    box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    transform: translateX(0%);
    transition: transform 0.3s ease-out;
    animation: slideIn 0.3s ease-in;
    border-radius: 8px 8px 0px 0px;
    &.slideOut {
      transform: translateX(100%);
    }
  }
  
  .closeIconContainer {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    right: 0;
    margin-right: 16px;
    margin-top: 16px;
    svg {
      cursor: pointer;
    }
  }