@import '../../../Styles/settings';

.titleLink {
  text-decoration: none;
}

.title {
  @include HelveticaMedium;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 16px;
  flex: 0 0 100%;
}

@media #{$md-up} {
  .title{
    font-size: 16px;
    line-height: 20px;
  }
}

@media print {
  .title {
    font-size: 18px;
    margin-bottom: 0;
  }
}
