@import './settings';

h1,
h2,
h3,
h4,
h5,
h6 {
  @include HelveticaMedium;
  font-weight: 500;
  line-height: $line-height-snug;
  margin: 15px 0;
}
h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 10px;
}

h6 {
  font-size: 8px;
}

p,
ul {
  font-weight: 400;
}

ul {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  &:first-of-type {
    margin-top: 0;
  }
}

a {
  color: inherit;
}

@media #{$md-up} {
  h1 {
    @include setRemToProp(font-size, $base-font-size-md, 64);
  }

  h2 {
    @include setRemToProp(font-size, $base-font-size-md, 36);
  }

  h3 {
    @include setRemToProp(font-size, $base-font-size-md, 24);
  }

  h4 {
    @include setRemToProp(font-size, $base-font-size-md, 18);
  }

  h5 {
    @include setRemToProp(font-size, $base-font-size-md, 10);
  }

  h6 {
    @include setRemToProp(font-size, $base-font-size-md, 8);
  }
}

@font-face {
  font-family: 'Zooja';
  font-display: swap;
  src: url('/Static/fonts/Aerotype.eot');
  src: url('/Static/fonts/Aerotype.woff') format('woff'),
    url('/Static/fonts/Aerotype.ttf') format('truetype'),
    url('/Static/fonts/Aerotype.otf') format('opentype');
}

@font-face {
  font-family: 'ZoojaLight';
  font-display: swap;
  src: url('/Static/fonts/Aerotype.eot');
  src: url('/Static/fonts/Aerotype - Zooja Light Pro.ttf') format('truetype'),
    url('/Static/fonts/Aerotype - ZoojaLightPro.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue LT W05_65 Medium';
  font-display: swap;
  src: url('/Static/fonts/a51850b3-5f73-4683-9f3e-087797d908c9.woff2') format("woff2"), url('/Static/fonts/cd97a1bb-23cb-4024-be8b-bb3a0daeccae.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT W05_55 Roman';
  font-display: swap;
  src: url('/Static/fonts/7ec25fd5-2752-4d84-b307-af3faabc21bb.woff2') format("woff2"), url('/Static/fonts/625ebac6-edce-48a5-a1e6-9f6a677c35dc.woff') format('woff');
}
