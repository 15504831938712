@import '../../Styles/settings';

.root {
  width: calc(100% - 10px);
  max-width: 380px;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.closeSvg {
  height: 20px;
  width: 20px;
}

.backgroundImage {
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.textContainer {
    padding: 40px 10px 15px 10px;
    margin-top: -10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.imgContainer {
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
}

.photograph {
    height: 80px;
    width: 80px;
    display: block;
    margin: 0 auto;
    border-radius: 100%;
    border: solid 4px  white;
    margin-top: -40px;
    object-fit: cover;
}

.dottedLine {
    border: none;
    width: 100%;
    border-top: 2px dotted black;
    margin: 20px 0;
}

.svg {
    width: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.emailLink {
    line-height: 30px;
}

.title {
    font-size: 14px;
    margin-bottom: 15px;
}


@include media(600px) {
  .root {
    max-width: 570px;
  }
}

@media #{$sm-up} {
    .backgroundImage {
        height: 120px;
    }

    .textContainer {
        padding-top: 80px;
    }

    .imgContainer {
        /*
        * using "important" because when in edit mode epi's [data-epi-property-name] injected class
        * will override the rule.
        */
        min-height: 120px !important;
    }

    .photograph {
        height: 120px;
        width: 120px;
        margin-top: -60px;
    }

    .dottedLine {
        margin: 30px 0;
    }
}