@import '../../Styles/settings';

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutterContentArea;
  margin-left: -$gutterContentArea;
}

.gutterNone {
  margin-left: 0px;
  margin-right: 0px;
}

.gap-8 {
  gap: 8px;
}

.gap-24 {
  gap: 24px;
}

.gutter-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.gutter-16 {
  margin-left: -16px;
  margin-right: -16px;
}

.gutter-25 {
  margin-left: -25px;
  margin-right: -25px;
}

.justifyBetween {
  justify-content: space-between;
}

.flexReverse {
  flex-direction: row-reverse;
}

@media print {
  .grid {
    display: block;
  }
}
