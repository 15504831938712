@import '../../../Styles/settings';

.imgLink {
  order: -1;
  display: block;
  flex-grow: 0;
  height: 48px;
}

.imgContainer {
  align-items: center;
  height: 48px;
  width: 48px;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media #{$md-up} {
  .imgLink {
    height: 72px;
  }

  .imgContainer {
    height: 72px;
    width: 72px;
  }
}
