
.button {
    background: 'url(/dist/img/icons/menu-black.svg) no-repeat';
    height: '20px';
    width: '20px';
    background-position: 'center';
    background-size: 'contain';
    border: 'none';
    position: 'absolute';
    background-color: 'white';
}