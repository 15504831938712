@import '../../../Styles/settings';

.imgLink {
  width: 100%;
}

.imgContainer {
  flex-grow: 1;
  align-items: flex-start;
  display: flex;
  width: 100%;

  &.homeowner {
    margin-bottom: 16px;
  }
  &.professional {
    margin-bottom: 8px;
  }
}

.img {
  max-height: 300px;
}

@media #{$md-up} {
  :global([data-style='cards-as-grid']) {
    .imgContainer {
      margin-bottom: 24px;
    }
  }

  :global([data-style='cards-as-list']) {
    .imgLink {
      height: 72px;
      display: block;
      flex-grow: 0;
      width: 64px;
    }

    .img {
      max-height: 72px;
      margin-left: auto;
      margin-right: auto;
    }

    .imgContainer {
      align-items: center;
      min-height: auto;
      height: 72px;
      margin-bottom: 0px;
    }
  }
}

@media print {
  .imgLink {
    height: 72px;
    display: block;
    flex-grow: 0;
    width: 64px;
  }
  .imgContainer {
    height: 72px;
  }
  .picture {
    height: 100%;
    width: auto;
  }
}
