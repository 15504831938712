.colorBlendResultRow {
    display: flex;
    height: 96px;
    padding: 16px;
    gap: 16px;
    flex: 1;
    .square {
        width: 64px;
        height: 64px;
        border-radius: 8px;
    }
    .image {
        width: 64px;
        height: 64px;
        object-fit: contain;
    }
    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .name {
            font-weight: 600;
            color: #00205c;
        }
    }
}
