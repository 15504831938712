@import '../../Styles/settings';

@keyframes fade-in-fwd {
  0% {
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

.imgFit {
  max-width: 100%;
  height: auto;
  display: block;
  /* for ie 11*/
  flex-shrink: 0;
}

.picture {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.placeHolder {
  width: 100%;
  height: 100%;
  background: no-repeat center url('/dist/img/icons/img-placeholder.svg');
  background-size: contain;
}

.span {
  display: inline-block;
  height: 1px;
  width: 1px;
}
