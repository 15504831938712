@import '../../Styles/settings';

.footerContainer{
  padding: 20px 0px 33px;
  background: $color-footer;
  color: $white;
}

.xhtml {
  font-size: 12px;
  padding: 0 15px;
  text-align: left;
}

.contactUs {
  border-top: 1px solid $midnight_border;
  margin: 0 -15px;
}

.title {
  @include HelveticaMedium;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 15px;

  &.isOpen {
    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    path {
      stroke: $white;
    }
  }
}

@media #{$md-up} {
  .footer {
  }

  .footerContainer{
    padding: 20px 0;

  }

  .xhtml {
    border-top: unset;
    padding: unset;
  }
  .title {
    margin-bottom: 20px;
    padding: unset;
  }

  .contactUs {
    border-top: unset;
    padding: 20px;
  }
}

@media print {
  .footer {
    display: none;
  }
}
