@import '../../../Styles/settings';

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke: $color-brand;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: map-get($zIndexes, 'loadingFixedCircle');
  overflow: hidden;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s $main-timing-function;
  pointer-events: none;
}

.wrapper {
  position: absolute;
  top: calc(40%);
  right: 50%;
  transform: translate(50%, -50%);
  background: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  box-shadow: 0px 10px 10px -10px black;
  transition: opacity 0.3s $main-timing-function 0.5s;
  opacity: 0;
}
.spinner {
  position: absolute;
  top: calc(50% - 25px);
  right: calc(50% - 25px);
  width: 50px;
  height: 50px;
}

.path {
  stroke: $color-brand;
  stroke-linecap: round;
}

.isLoading {
  opacity: 1;
  .wrapper {
    opacity: 1;
  }
  .spinner {
    animation: rotate 2s linear infinite;
  }

  .path {
    animation: dash 1.5s ease-in-out infinite;
  }
}
