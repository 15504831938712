@import '../../Styles/settings';

.contentContainer {
  max-width: $content-container-width + $gutterContentArea * 2;
  width: 100%;
  padding: 0 $gutterContentArea;
  margin: 0 auto;
}

.gutter-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.gutter-none {
  padding-left: 0px;
  padding-right: 0px;
}

.gutter-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.gutter-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.gutter-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.shadow {
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
}
