@import './Styles/settings';
@import './Styles/normalizer';
@import './Styles/typography';
@import './Styles/editor';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: $line-height;
  letter-spacing: 0px;
  font-size: $base-font-size;
  height: 100%;
  background: white;
  &.wf-active {
    body {
      font-family: $font-family;
    }
  }
}
body {
  font-family: $font-family;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $near-black;
  overflow-x: hidden;
  min-height: 100%;

  &:not(.IE) {
    #root {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
    main {
      flex-grow: 1;
      width: 100%;
    }
  }
}

#root {
  overflow-x: hidden;
}

:focus:not(:focus-visible) {
  outline: none;
}

#{$rootLockedClass} {
  position: fixed;
  left: 0;
  right: 0;
  overflow-y: scroll;

  body:not(.IE) & {
    &#root {
      display: block;
      height: auto;
    }
  }
}

@media #{$md-up} {
  html {
    line-height: $line-height-md;
    font-size: $base-font-size-md;
  }
}

@media print {
  body:not(.IE) {
    #root {
      display: block;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: $mercury;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $silver;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

table {
  max-width: 100%;
  border: $light-cyan;
  overflow: auto;
  display: inline-block;
  color: $midnight-blue;

  // to overwrite styles that come from BE
  width: initial !important;
  height: initial !important;

  td {
    padding: 12px;
    text-align: center;
  }

  tr:first-child, td:first-child {
    color: $near-black;
    text-align: left;
  }

  tr:first-child {
    background-color: $lightest-blue;

    td {
      text-align: left;
      padding: 20px 12px;
    }
  }
}

table::-webkit-scrollbar {
  height: 8px;
}
