@import '../../Styles/settings';

.small-label {
  color: $dark-gray;
  line-height: 12px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-size: 10px;
  @include HelveticaMedium;
}
