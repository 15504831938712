@import '../../Styles/settings';

.iframe {
  width: 100%;
  overflow-y: hidden;
  max-width: 770px;
  margin: 0 auto;
  display: block;
  border: 0;
}

.gutterBottom{
  padding-bottom: $gutterBottomXS;
}

.verticalMargin {
  margin-top: $verticleBlockPaddingXS;
  margin-bottom: $verticleBlockPaddingXS;
}

@media #{$sm-up} {
  .gutterBottom{
    padding-bottom: $gutterBottomSM;
  }
}


@media #{$md-up} {
  .verticalMargin {
    margin-top: $verticleBlockPaddingMD;
    margin-bottom: $verticleBlockPaddingMD;
  }
}
