@import '../../../Styles/settings';

.input {
  @include text-input;

  &[disabled] {
    + label {
      display: none;
    }
    &:hover {
      border: 1px solid $near-white !important;
    }
  }
}

.placeholderAsText {
  &::placeholder {
    color: $text-color;
    font-family: $font-family;
  }
}

.errorContainer {
  position: relative;
  width: 100%;
}

.errorMessageHeading {
  font-size: 10px;
  position: absolute;
  top: -6px;
  left: 10px;
  background: white;
  padding: 0 5px;
  line-height: 10px;
}

.errorMesssage {
  padding-top: 5px;
  display: block;
  color: $color-error;
}

.required {
  color: red;
}

.input:not(.error):not(:focus):hover {
  border: 1px solid $near-black;
}

.input:focus {
  border: 1px solid $light-blue;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
}

.floatLabel:focus-within label {
  color: $light-blue;
}

.floatLabel {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
}

.floatLabel input {
  width: 100%;
  height: 48px;
  padding: 0px 16px 0 10px;
  border-radius: 4px;
  background: #fff;
  font-size: 16px;
}

.floatLabel label {
  font-size: 16px;
  margin-left: 12px;
  color: $dark-gray;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 12px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  background-color: white;
}

.floatLabel input:not(:placeholder-shown) + label,
.floatLabel:focus-within label {
  transform: translate(16px, -8px) scale(0.75);
  margin-left: 0px;
  padding: 0 4px;
}

.error {
  @include input-has-error;
  &:focus {
    border: 1px solid $color-error;
  }

  + label {
    color: $color-error !important;
  }
}

.icon {
  @include icon-inside-input;
}
