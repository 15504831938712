@import '../../Styles/settings';

.backgroundImage {
  position: absolute;
  z-index: 0;
}

.topLeftBackgroundImage {
  top: 0;
  left: 0;
}

.topRightBackgroundImage {
  right: 0;
  top: 20%;
}

.bottomBackgroundImage {
  right: 0;
  bottom: 0;
}

.rounded{
  border-radius: 8px;
}

@media #{$md-up} {
  .topRightBackgroundImage {
    top: 0;
  }

  .bottomBackgroundImage {
    right: 20%;
  }
}

