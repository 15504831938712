@import '../../Styles/settings';

.container {
    z-index: 999;
    background: $color-impersonation-area;
    position: fixed;
    bottom: 8px;
    right: 8px;
    display: flex;
}

.infoSection {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    padding-left: 24px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    &:hover{
        cursor: pointer;
        background: darken($color-impersonation-area, 8%);
    }
}

.userIcon {
    height: 22px;
    display: inline-block;
    vertical-align: sub;
    margin: auto;
  }

.closeIcon {
    width: 18px;
    height: 18px;
}

.textContainer {
    color: white;
    padding: 0 12px;
    border-right: white;
    border-right-style: solid;
    border-right-width: 1px;
    min-height: 38px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.infoSpan {
    font-size: 12px;
    font-weight: bold;
}

.userNameSpan {
    font-size: 12px;
}

@media #{$sm-up} {
.container {
    right: 32px;
    bottom: 32px;
    &.moreRight {
        right: 124px;
    }
}
}