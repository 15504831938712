@import '../../../Styles/settings';

.root {
  align-self: flex-start;
  &.grid {
    gap: 8px;
    &.homeowner {
      height: 48px;
      margin-bottom: 16px;
    }
    &.professional {
      height: 32px;
      margin-bottom: 8px;
    }
  }

  &.list {
    flex-grow: 1;
    flex-shrink: 0;
  }
}

@media #{$md-up} {
  .root {
    &.grid {
      &.homeowner {
        height: 60px;
      }
      &.professional {
        height: 48px;
      }
      margin-bottom: 14px;
    }
  }

  .list {
    flex-direction: column;
    align-items: flex-start;
    align-self: auto;
    display: flex;
    padding-left: 24px;
    padding-right: 8px;
    margin-bottom: 0;
  }
}

@media print {
  .root {
    align-self: auto;
    display: flex;
    align-items: center;
    max-width: none;
    flex: 1 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
  }
}
