@import '../../../Styles/settings';
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  height: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: fadeIn 0.3s $main-timing-function 0.7s forwards;
}
