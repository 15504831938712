@import '../../../Styles/settings';

.nowrap {
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
}

.variantPropertiesListContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 11px;
}
.variantPropertiesListView {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: $darkest-gray;
  display: flex;

  &.hasUom {
    margin-right: 16px;
  }
}

.variantProperties {
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: $darkest-gray;
}

.variantPropertyListView {
  padding-right: 16px;
  padding-left: 16px;
  border-right: 1px solid $light-cyan;
  display: flex;
  flex-direction: column;
  &:not(.hasUom) {
    &:last-child {
      border-right: 0px solid $light-cyan;
    }
  }
  &:first-child {
    padding-left: 0px;
  }
}

.variantProperty {
  padding-top: 8px;
  padding-bottom: 7px;
  border-bottom: 1px solid $light-cyan;

  &:first-child {
    padding-top: 7px;
    border-top: 1px solid $light-cyan;
  }
}

@media #{$md-up} {
  .variantProperties,
  .variantPropertiesListView {
    font-size: 14px;
  }
}
