@import '../../../Styles/settings';

.icon {
    height: 80px;
    width: 80px;
    display: block;
    margin: 0 auto;
    border-radius: 100%;
    border: solid 4px  white;
    margin-top: -40px;
    object-fit: cover;
    background-color: $eden;
}

.iconSvg {
    display: block;
    margin: 0 auto;
    width: 30px;
    height: 100%;
    fill: $white;
}

.textWrapper {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

.dottedLine {
    border: none;
    width: 100%;
    border-top: 2px dotted black;
    margin: 20px 0;
}

.svg {
    width: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.svgPhone {
    width: 15px;
    margin-right: 12px;
    margin-left: 3px;
}

.contactLink {
    display: block;
    margin-bottom: 10px;
    float: none;
}

.header {
    margin-bottom: 10px;
    font-size: 19px;
}

.subHeader {
  font-weight: normal;
  font-size: 1.1rem;
}

.date {
    font-size: 14px;
    margin-bottom: 15px;
    font-family: $font-family-fallback;
}

.contactUsLabel {
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 5px;
}

@include media(500px, null) {
    .header {
        @include setRemToProp(font-size, $base-font-size, 23);
    }
  }

@media #{$sm-up} {
    .dottedLine {
        margin: 30px 0;
    }

    .icon {
        height: 120px;
        width: 120px;
        margin-top: -60px;
    }
    
    .iconSvg {
        width: 45px;
    }
    
    .floatLeft {
        float: left;
    }

    .svgPhone {
        margin-left: 5px;
        margin-right: 10px;
    }
}

@media #{$md-up} {
    .header {
        @include setRemToProp(font-size, $base-font-size-md, 26);
    }
}