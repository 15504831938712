.root {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  word-break: break-word;

  &.tight {
    margin-bottom: 16px;
  }
}

.twoColumn {
  flex-wrap: unset;
  gap: 16px;
}

.twoThirdsColumn {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
}

.oneThirdsColumn {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 16px;
}
