@import '../../../Styles/settings';

.root {
  display: flex;
  width: 100%;
  margin-bottom: 2px;
  position: relative;
}

.wrapper {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  align-items: center;
  background: white;
  padding: 10px;
  &.noBgListMode {
    background: transparent;
  }
}

.root {
  &.noBgListMode {
    z-index: map-get($zIndexes, 'productCard');
  }
  &.disabled {
    opacity: 1;
  }
}

@include media(800px) {
  .wrapper {
    padding: 10px 15px;
  }
}

@media #{$md-up} {
  .wrapper {
    padding: 20px 30px;
  }
}
