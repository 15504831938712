@import '../../../Styles/settings';

.details {
  display: block;
  color: black;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.56;
  letter-spacing: 0.1px;
  text-decoration: none;
  flex: 1 0 100%;
  max-width: calc(100% - 20px);
}

@include media(800px) {
  .details {
    width: 50%;
    font-size: 14px;
  }
}
