@import '../../../Styles/settings';

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}

@keyframes waveSmall {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-6px);
  }
}

.root {
  position: relative;
  display: inline-block;
  text-align: center;
}

.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: wave 1.3s linear infinite;
  &:nth-child(2) {
    animation-delay: -1.1s;
  }
  &:nth-child(3) {
    animation-delay: -0.9s;
  }
}

.black {
  background: $text-color;
}

.blue{
  background: $midnight-blue;
}

.white {
  background: white;
}
