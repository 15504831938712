.sliderArrow {
    cursor: pointer;
    width: 32px;
    height: 40px;
    background-color: #00205c;
    border-radius: 4px;
    display: flex;

    &.disabled {
        display: none;
    }
    &.left::after,
    &.right::after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        margin: auto;
    }
    &.left::after {
        background-image: url('data:image/svg+xml,<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.667 5.50002L1.33366 5.50002M1.33366 5.50002L6.00033 10.1667M1.33366 5.50002L6.00033 0.833354" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    &.right::after {
        background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.33301 6.00004H10.6663M10.6663 6.00004L5.99967 1.33337M10.6663 6.00004L5.99967 10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
}

// !important is bad, but it's the only way to override the inline styles from nuka-carousel
:global(.slider-control-centerright),
:global(.slider-control-centerleft) {
    top: 45% !important;
    transform: none !important;
}
:global(.slider-control-centerright) {
    right: -12px !important;
}
:global(.slider-control-centerleft) {
    left: -12px !important;
}
