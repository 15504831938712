@import '../../../Styles/settings';
$defaultBaseColor: $pampas;

$defaultHighlightColor: #fff;

@keyframes skeleton {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.line {
  background-color: $defaultBaseColor;
  background-image: linear-gradient(
    90deg,
    $defaultBaseColor,
    $defaultHighlightColor,
    $defaultBaseColor
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 1px;
  width: 100%;
  height: 100%;
  animation: skeleton 1.2s ease-in-out 0.7s infinite;
}

.wrapper {
  height: $mobile-header-height;
  background: white;
}

@media #{$md-up} {
  .wrapperStandardHeader {
    height: 122px;
  }

  .wrapperLandingPage {
    height: 75px;
  }
}
