@import '../../Styles/settings';

.skipToContentLink {
    position: absolute;
    top: 2px;
    left: -100%;
    padding: 5px 10px;
    background: white;
    text-decoration: none;
    z-index: 1000;
    &:focus {
      left: 5px;
      transition: left 0.2s $main-timing-function
    }
  }