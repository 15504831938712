.colorBlendButton {
    display: flex;
    background-color: #00205c;
    font-weight: 500;
    font-size: 14px;
    color: white;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px;
    height: 48px;
    padding: 0 16px;
    gap: 8px;
    cursor: pointer;
    &.disabled {
        background-color: #d3d3d3;
        color: #a9a9a9;
        pointer-events: none;
    }
    &.fullWidth {
        width: 100%;
    }
}
