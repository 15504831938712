@import '../../../Styles/settings';
.heading {
  @include HelveticaMedium;
  font-size: 36px;
  line-height: 40px;
  color: $midnight-blue;
}

.infoContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
}

.informations {
  margin-top: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.rightColumn {
  padding-top: 120px;
  padding-bottom: 72px;
  position: relative;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.signupSection {
  margin-top: 48px;
}

@media #{$md-up} {
  .signupSection {
    display: flex;
    flex-direction: row-reverse;
  }

  .container {
    padding-left: 40px;
    display: flex;
  }
  .heading {
    font-size: 60px;
    line-height: 64px;
  }

  .rightColumn {
    margin-left: 64px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .informations {
    margin-top: 32px;
  }
}
