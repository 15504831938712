@import "../../../Styles/settings";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  row-gap: 8px;
  margin-bottom: 16px;
  height: 100%;

  :global(.slider-control-bottomcenter) {
    display: block;
    width: 100%;
    text-align: center;
    bottom: -40px !important;
    button {
      font-size: 30px !important;
    }
  }

  .picture {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  :global(.slider-frame) {
    display: flex !important;
    align-items: center;
  }

  :global(.slider) {
    position: static !important;
  }

  :global(.slider-control-bottomcenter) {
    overflow: hidden;
    white-space: nowrap;
  }
  .customDotsSpacing {
    margin-bottom: 50px;
  }
  .scrollArea {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    display: flex;
    overflow: auto;
    scroll-behavior: smooth;
  }
  .scrollArea::-webkit-scrollbar {
    display: none;
  }
}

.buttonContainer {
  margin-left: 16px;
  margin-right: 16px;
}

.thumbnail {
  padding: 0;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  height: 80px;
  width: 104px;
  margin-bottom: 10px;
  margin-right: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
  flex: 0 0 auto;
}

.thumbnailActive {
  border: 1px solid $light-blue;
}

.thumbnailImg {
  height: 100%;
  width: 100%;

  object-fit: cover;
}

.thumbnailsContainer {
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
}

.thumbnailsContainerCount {
  position: absolute;
  bottom: 7px;
  left: 16px;
  padding: 4px 15px;
  background-color: $white;
  border-radius: 4px;
}

.mediaContentRoot,
.mediaContentIframe {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  background-color: $near-white;
}

.mediaContentIframe {
  height: 270px;
  background: black;
}

@media #{$sm-up} {
  .mediaContentIframe {
    height: 550px;
  }
}

@media #{$md-up} {
  .root {
    margin-bottom: 0;
    :global(.slider-control-bottomcenter) {
      bottom: -50px !important;
      button {
        font-size: 32px !important;
      }
    }

    :global(.slider) {
      position: static !important;
    }
  }

  .thumbnailsContainer {
    top: 14px;
  }

  .mediaContentIframe {
    height: 405px;
  }

  .buttonContainer {
    margin-bottom: 100px;
  }
}

//placing this selector here in order to override all the other selectors in media queries
.noThumbnailsContainer {
  padding-left: 0;
}
