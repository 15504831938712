@import '../../../Styles/settings';

.root {
  display: flex;
  grid-column-end: span 2;
  margin-bottom: 10px;
  position: relative;
  flex-direction: column;
  width: 100%;
  :global(body.IE) & {
    flex-direction: row;
  }
  &.disabled {
    opacity: 0.5;
  }
  &.grid {
    flex-direction: column;
    &.homeowner {
      &.isLoggedIn {
        min-height: 360px;
      }

      &:not(.isLoggedIn) {
        min-height: 308px;
      }
    }

    &.professional {
      min-height: 324px;
    }
  }
}

.wrapper {
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(111, 114, 113, 0.08);
  display: flex;
  background: white;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px;
  flex-grow: 1;
  align-items: flex-start;

  &.grid {
    flex-direction: column;
  }
}

.minHeight {
  min-height: 380px;
}

@include media(350px) {
  .root {
    margin-bottom: 2px;
  }
}

@include media(null, 480px) {
  .root {
    grid-column-end: span 4;
  }

  .homeowner {
    grid-column-end: span 2;
  }
}

@media #{$sm-up} {
  :global([data-style='cards-as-list']) {
    .campaignPrice,
    .price,
    .priceStriked {
      font-size: 18px;
    }
  }
}

@media #{$md-up} {
  .root {
    grid-column-end: span 4;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s $main-timing-function;

    &:hover {
      z-index: map-get($zIndexes, 'productCard');
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    }
    &.grid {
      min-height: 436px;
      grid-column-end: span 4;

      &.homeowner {
        &.isLoggedIn {
          min-height: 424px;
        }

        &:not(.isLoggedIn) {
          min-height: 326px;
        }
      }

      &.professional {
        min-height: 436px;
      }
    }
    &.list {
      min-height: 104px;
      width: 100%;
      grid-column-end: span 12;
    }
  }

  .wrapper {
    padding: 16px;
  }

  :global([data-style='cards-as-list']) {
    .root {
      &:hover {
        box-shadow: none;
        z-index: initial;
      }
      &.disabled {
        opacity: 1;
      }
    }

    .wrapper {
      border-radius: 8px;
      flex-direction: row;
      align-items: center;
      padding: 10px 15px;
      &.noBgListMode {
        background: transparent;
      }
    }
  }
}

@media #{$lg-up} {
  :global([data-style='cards-as-list']) {
    .wrapper {
      padding: 16px;
    }
  }
}

@media print {
  .root {
    display: block;
    &.noBgListMode {
      z-index: map-get($zIndexes, 'productCard');
    }

    &:hover {
      box-shadow: none;
    }
    &.disabled {
      opacity: 1;
    }
  }

  .wrapper {
    flex-direction: row;
    align-items: center;
    padding: 20px 30px;
    padding-right: 30px;
    &.noBgListMode {
      background: transparent;
    }
  }
}
