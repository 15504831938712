@import '../../Styles/settings';

.root {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  flex-direction: column;
}

.whiteText {
  color: white;
}

.zooja {
  @include zoojaFont;
}

.textContainer {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.textWrapper {
  padding-bottom: 20px;
}

.header {
  margin-top: 0;
  padding-top: 20px;
  font-size: 19px;
  &.zooja {
    font-size: 2.5em;
  }
}

:global(.slider) {
  .root {
    padding-bottom: 60px;
  }
}

.linkWrapper {
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

@include media(500px, null) {
  .header {
    &.zooja {
      font-size: 2.5em;
    }
    @include setRemToProp(font-size, $base-font-size, 23);
  }
}

@media #{$sm-up} {
  .root {
    flex-direction: row;
  }

  .textWrapper {
    padding-bottom: 0px;
  }

  .header {
    padding-top: 20px;
  }

  .textContainer {
    flex-grow: 1;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media #{$md-up} {
  .header {
    &.zooja {
      font-size: 2.5em;
    }
    padding-top: 25px;
    @include setRemToProp(font-size, $base-font-size-md, 26);
  }
  .textContainer {
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
