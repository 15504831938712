@import '../../../Styles/settings';

.root {
  display: block;
  font-size: 12px;
  color: $near-black;
  line-height: 16px;

  &:not(:empty) {
    padding-top: 8px;
  }
}

@include media(600px) {
  .root {
    display: block;
  }
}

@media #{$md-up} {
  .root {
    display: block;
  }
}

@media #{$md-down} {
  .root {
    &:not(:empty) {
      padding-top: 12px;
    }
  }
}
