@import '../../Styles/settings';

.root {
    padding: $verticleBlockPaddingXS 0;
}

.colorBlendBlock {
    grid-column-end: span 12;
    padding: 96px 64px;
    gap: 24px;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    @media only screen and (max-width: 769px) {
        background-size: 0;
        padding: 0;
    }
}

.colorBlendWizard {
    background: $near-white;
    width: 424px;
    padding: 32px;
    border-bottom: 5px solid transparent;
    border-image: linear-gradient(0.25turn, #d81b30, #692b81, #53aae0, #4db13e, #f7d90d, #004fe0);
    border-image-slice: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    h2 {
        margin: 0 auto;
        max-width: 220px;
    }
}

.selectProduct {
    display: flex;
    gap: 16px;
}

.colorBlendWhiteButton {
    display: flex;
    color: #00205c;
    font-weight: 500;
    font-size: 14px;
    background-color: white;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 48px;
    gap: 8px;
    padding: 0 16px;
    cursor: pointer;
}
.previewArea {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    text-align: left;
}
.header {
    font-weight: 600;
    color: #00205c;
}
.spinner {
    width: 16px;
    height: 16px;
    animation: rotate 2s linear infinite;
}

.path {
    stroke: white;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
        stroke: $color-brand;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

.colorBlendSimpleColorRow {
    padding: 16px;
}
.summary {
    display: flex;
    gap: 16px;
}

.summaryItem {
    flex: 1;
}

.colorBlendSelectRadio {
    display: none;
}

.searchArea {
    display: flex;
    gap: 8px;
}

.searchContainer {
    position: relative;
}

.resultComment {
    padding-top: 16px;
    color: $dark-gray;
    border-top: solid #dee1e6 1px;
}
