@import "../../Styles/settings";

.root {
    max-width: 345px;
    margin: auto;
}
.imgFill {
    object-fit: cover;
}
.imgContainer {
    height: 345px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    order: 1;
    margin-bottom: 16px;
    flex-shrink: 0;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.backgroundImage {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.textArea {
    width: 100%;
    padding-bottom: 20px;
}

.heading {
    font-size: 20px;
    margin-bottom: 8px;
}

.text {
    font-weight: 400;
    font-size: 16px;
}

@media #{$md-up} {
    .root {
        &.noMaxWidth {
            max-width: unset;
        }
    }

    .imgContainer {
        height: 264px;
    }

    .heading {
        font-size: 18px;
    }
}
