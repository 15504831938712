@import '../../../Styles/settings';

.photograph {
    height: 80px;
    width: 80px;
    display: block;
    margin: 0 auto;
    border-radius: 100%;
    border: solid 4px  white;
    margin-top: -40px;
    object-fit: cover;
}

.header {
    font-size: 19px;
}

.textWrapper {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.dottedLine {
    border: none;
    width: 100%;
    border-top: 2px dotted black;
    margin: 20px 0;
}

.svg {
    width: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.emailLink {
    line-height: 30px;
}

.title {
    font-size: 14px;
    margin-bottom: 15px;
}

@include media(500px, null) {
    .header {
        @include setRemToProp(font-size, $base-font-size, 23);
    }
}

@media #{$sm-up} {
    .dottedLine {
        margin: 30px 0;
    }

    .photograph {
        height: 120px;
        width: 120px;
        margin-top: -60px;
    }
}

@media #{$md-up} {
    .header {
        @include setRemToProp(font-size, $base-font-size-md, 26);
    }
}