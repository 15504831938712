@import '../../Styles/settings';

.tooltip {
  --tooltip-content-left: -170px;
  --tooltip-arrow-left: 90%;

  @media #{$md-up} {
    --tooltip-content-left: -90px;
    --tooltip-arrow-left: 50%;
  }

  @include icon-inside-input;

  svg {
    cursor: pointer;
  }

  &__content {
    position: absolute;
    left: var(--tooltip-content-left);
    opacity: 0;
    transition: 0.25s all;
    width: 200px;
    background-color: $bona-dark-blue;
    color: $near-white;
    transform: translate3d(0, 20px, 100px) rotateX(20deg);
    transform-origin: 50% 0;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    display: inline-block;

    &--show {
      opacity: 1;
      transform: translateZ(0) rotateX(0deg);
    }

    &::before {
      content: ' ';
      left: var(--tooltip-arrow-left);
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-left: calc(6px * -1);
      top: 100%;
      border-top-color: $bona-dark-blue;
    }
  }
}
