@import "../../Styles/settings";

@keyframes bgAnimation {
  0% {
    margin-left: 0;
    margin-right: 10px;
  }

  50% {
    margin-left: -10px;
    margin-right: 20px;
  }

  100% {
    margin-right: 10px;
    margin-left: 0px;
  }
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  z-index: -1;
  margin-left: -50vw;
  left: 50%;
}

.whiteText {
  color: white;
}

.root {
  padding: $verticleBlockPaddingXS 0;
  max-width: 100%;
  flex: 0 0 100%;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.heading {
  margin-top: 0;
  font-weight: 300;
}

.body {
  margin-bottom: 20px;
  font-weight: 300;
  font-size: calc(1rem + 2px);
}

.zooja {
  @include zoojaFont;
}

.linkEditPlaceHolder {
  display: block;
  height: 20px;
  width: 50px;
  float: right;
}

.linkEditPlaceHolder {
  display: block;
  height: 20px;
  width: 50px;
  float: right;
}

.hasSlider {
  padding-bottom: 50px;
}

.span {
  border-bottom: 1px solid $tundora;
  padding-bottom: 7px;
}

.linkContainer {
  display: flex;
  flex-direction: row-reverse;
  &.linkContainerBlockNoSlider {
    padding-top: 25px - $gutterContentArea;
  }

  &:empty {
    display: none;
  }
}

.gutterBottom {
  margin-bottom: $gutterBottomXS;
}

.mainContentWrapper {
  &:empty {
    display: none;
  }
}

@include media(615px) {
  .contentContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

@media #{$sm-up} {
  .linkContainer {
    padding-top: 25px - $gutterContentArea;
  }
  .gutterBottom {
    margin-bottom: $gutterBottomSM;
  }
}

@media #{$md-up} {
  .root {
    padding: $verticleBlockPaddingMD 0;
  }
}

.noVerticalPaddingRoot {
  padding-top: 0px;
  padding-bottom: 0px;
}
