@import '../../../Styles/settings';

.root {
  width: 100%;
}

.listRoot {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 11px;
}

.selectedOptionText {
  &:first-letter {
    text-transform: capitalize;
  }
}

.capitalize {
  &:first-letter {
    text-transform: capitalize;
  }
}

.variantBtnImg {
  margin-left: auto;
}

.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-cyan;
  padding-top: 2px;
  padding-bottom: 2px;

  &.listOption {
    border-bottom: 1px solid $light-cyan;
  }

  &:last-child {
    border: 0;
  }

  &.notBuyable {
    cursor: default;
    color: $bona-border-grey;
  }

  &.selected {
    color: $midnight-blue;
  }

  svg {
    margin-right: 8px;
  }
}

li.liList {
  padding: 0 8px 0 8px;
  font-size: 12px;
  overflow: hidden;
  border-right: 1px solid $light-cyan;

  &:not(.hasUom) {
    &:last-child {
      border-right: 0px solid $light-cyan;
    }
  }

  &:first-child {
    padding-left: 0;
  }
}

li.li {
  border-bottom: 1px solid $color-tabs-nav-border-phone;
  padding: 0;
  font-size: 12px;
  overflow: hidden;

  &:first-of-type {
    border-top: 1px solid $color-tabs-nav-border-phone;
  }

  &.selected {
    border-bottom: 1px solid $midnight-blue;
    border-top: 1px solid $midnight-blue;
  }
}

.ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  column-gap: 4px;
}

.ulList {
  list-style: none;
  padding: 0 16px 0 0;
  display: flex;
  flex-direction: row;
  margin: 0;
  min-height: 34px;
}

.btnContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.btnList {
  min-width: 96px;
  padding: 0;
  color: $midnight-blue;
  width: 100%;
  border: none;
  align-items: center;
  line-height: 16px;
  display: flex;
  background: transparent;
  font-size: 12px;
  text-decoration: none;
  letter-spacing: 0px;
  cursor: pointer;
  position: relative;
  z-index: map-get($zIndexes, 'tabsBtn');
  transition: color 0.3s $main-timing-function;
  border-radius: 4px 4px 0px 0px;
  word-wrap: break-word;

  &:not(.uniqueSelected){
    @include HelveticaMedium;
  }


  svg {
    align-self: flex-start;
    margin-left: auto;
    height: 16px;
    width: 16px;
    transition: transform 0.2s $main-timing-function;
    flex-shrink: 0;
  }

  &.uniqueSelected{
    color: $darkest-gray; 
    cursor: default; 
  }
  

  &.selected {
    color: $color-tabs-nav-selected;
    svg {
      transform: rotate(180deg);
    }
  }
}

.btn {
  color: $midnight-blue;
  width: 100%;
  border: none;
  align-items: center;
  line-height: 16px;
  display: flex;
  background: transparent;
  font-size: 12px;
  padding: 8px 0px 8px 0px;
  text-decoration: none;
  letter-spacing: 0px;
  cursor: pointer;
  position: relative;
  z-index: map-get($zIndexes, 'tabsBtn');
  transition: color 0.3s $main-timing-function;
  border-radius: 4px 4px 0px 0px;
  word-wrap: break-word;

  &:not(.uniqueSelected){
    @include HelveticaMedium;

  }

  svg {
    margin-left: auto;
    height: 16px;
    width: 16px;
    transition: transform 0.2s $main-timing-function;
    flex-shrink: 0;
  }

  &.uniqueSelected{
    color: $darkest-gray; 
    cursor: default; 
  }
  

  &.selected {
    color: $color-tabs-nav-selected;
    svg {
      transform: rotate(180deg);
    }
  }
}

.slideDownOuter {
  height: 0;
  overflow: hidden;
  transition: height 0.3s $main-timing-function;
}

.slideDownOuterList {
  border-radius: 8px;
  min-width: 180px;
  box-shadow: 0px 4px 20px rgba(111, 114, 113, 0.08);
  position: absolute;
  background: $white;
  height: 0;
  overflow: hidden;
  transition: height 0.3s $main-timing-function;
  z-index: map-get($zIndexes, 'productCardListDropdown');
}

.slideDownInner {
  padding-left: 5px;
  max-height: 132px;
  overflow-y: auto;
  padding-bottom: 8px;
}

.slideDownInnerList {
  padding: 8px;
  overflow-y: auto;
}

.slideDownExpanded {
  height: auto;
}

.hide {
  display: none;
  overflow: hidden;
}

@media #{$md-up} {
  .btn {
    font-size: 14px;
  }

  .slideDownInner {
    max-height: 152px;
  }
}
