@import "../../Styles/settings";

.bold {
    font-weight: bold;
}

.slideDownOuter {
    height: 0;
    overflow: hidden;
    transition: height 0.3s $main-timing-function;
}

.slideDownInner {
    display: block;
}

.slideDownExpanded {
    height: auto;
    padding: 0px 16px 16px 16px;
}

.questionBlock {
    width: 100%;
    border-bottom: solid 1px #dee1e6;
}
.answer {
    display: "flex";
    flex-direction: "column";
    p:last-child {
        margin-bottom: 0;
    }
    video {
        margin-top: 16px;
        max-width: 100%;
        height: auto;
        cursor: pointer;
    }
}
