@import '../../../Styles//settings';

.burger {

  position: relative;
  cursor: pointer;
  transition: 0.2s all;
  border: none;
  -webkit-tap-highlight-color: transparent;
  background-color: unset;
  z-index: 20;
  top: 5px;
  

  &.open.burger-up {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: translate3d(0,-4px,0) rotate(
      180deg);
  }

}
