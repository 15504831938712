@import "../../Styles/settings";

.root {
  position: relative;
  padding: $verticleBlockPaddingXS $gutterContentArea;
  margin-left: -$gutterContentArea;
  margin-right: -$gutterContentArea;
  grid-column-end: span 4;
  margin-bottom: $gutterContentArea * 2;
  :global(.slider-control-bottomright) {
    transform: translateY(50%);
    margin-right: $gutterContentArea;
  }
}

.zooja {
  @include zoojaFont;
}

.stepBtnContainer {
  display: flex;
  justify-content: space-between;
  padding: 30px $gutterContentArea 0;
}

.wrapper,
.wrapperCarousel {
  max-width: 600px;
  margin: 0 auto;
}

.wrapperCarousel {
  padding: 0 $gutterContentArea 40px;
}

.wrapperCarouselChildItems {
  margin-top: 40px;
}

.btn {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $color-brand;
  transition: background-color 0.3s $main-timing-function;
  &:hover {
    background-color: darken($color-brand, 8%);
  }

  border: none;
  cursor: pointer;
}

.btnLeft {
  background-image: url(/Dist/img/icons/arrow-left-white.svg);
  margin-right: 1px;
  &.disabled {
    background-color: $mercury;
    background-image: url(/Dist/img/icons/arrow-left-tundora.svg);
  }
}

.btnRight {
  background-image: url(/Dist/img/icons/arrow-right-white.svg);
  &.disabled {
    background-color: $mercury;
    background-image: url(/Dist/img/icons/arrow-right-tundora.svg);
  }
}

.imgFit {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.link {
  text-decoration: none;
}

.liDisabled {
  cursor: none;
  color: $mercury;
}

.steps {
  width: 100%;

  // inline styles overrides in order to position dots
  // relative to the image in the slider
  :global(.slider-control-bottomcenter) {
    transform: none !important;
    left: 0 !important;
    bottom: auto !important;
    top: 0;
    margin-top: 51.95%;
    width: 100%;
    text-align: center;
  }
  :global(.slider-control-centerright),
  :global(.slider-control-centerleft) {
    top: 0 !important;
    margin-top: 25.96%;
  }
}

.productCardCarouselWrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 8px;
  height: 100%;
  padding-bottom: 44px;
}

.sliderDotsSpacer {
  display: block;
  margin: 0;
  position: relative;
  padding: 10px 10px 0;
  text-align: center;
}

.dotSpacer {
  width: 10px;
  height: 22px;
  display: inline-block;
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0;
  }
}
.halfSizeCard {
  flex: 0 0 calc(50% - 8px);
}
.bodyText {
  margin-bottom: 30px;
}

.recommendedHeading {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 15px;
  padding: 10px 0 0;
}

.heading,
.bodyText {
  padding: 0 $gutterContentArea;
}

.gutterBottom {
  margin-bottom: $gutterBottomXS;
}

.whiteText {
  color: white;
}

.loadingContainer {
  height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
}

@include media(630px) {
  .recommendedHeading {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media #{$sm-up} {
  .root {
    grid-column-end: span 6;
    margin-left: -$gutterContentArea;
    margin-right: -$gutterContentArea;
    :global(.slider-control-bottomright) {
      margin-right: 0;
      transform: translateY(calc(100% + 20px));
    }
  }

  .productCardCarouselWrapper {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .heading,
  .bodyText {
    padding: 0;
  }

  .wrapper,
  .wrapperCarousel {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 800px;
  }

  .wrapperCarouselChildItems {
    margin-top: 45px;
  }

  .recommendedHeading {
    font-size: 22px;
  }

  .gutterBottom {
    margin-bottom: $gutterBottomSM;
  }

  .sliderDotsSpacer {
    padding-top: 15px;
  }
}

@media #{$md-up} {
  .root {
    grid-column-end: span 12;
    padding-top: $verticleBlockPaddingMD;
    padding-bottom: $verticleBlockPaddingMD;
  }
  .productCardCarouselWrapper {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    height: 100%;
  }
}

@include media($content-container-width) {
  .root {
    padding-left: $gutterContentArea * 2;
    padding-right: $gutterContentArea * 2;
    width: 100vw;
    margin-left: calc((#{$content-container-width} - 100vw) / 2);
    margin-right: calc((#{$content-container-width} - 100vw) / 2);
  }
}

.noVerticalPaddingRoot {
  padding-top: 2px;
  padding-bottom: 2px;
}
