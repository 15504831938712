@import '../../../Styles/settings';

@keyframes errorContainer {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}

.qtyParentContainer {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.root {
  margin-top: 8px;
  width: 100%;
}

.rootList {
  width: 100%;
}

.rootContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rootContainerList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.uomContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex-shrink: 0;
}

.title {
  @include HelveticaMedium;
  font-size: 16px;
  line-height: 20px;
  color: $near-black;
}

.qtyAndCtaBtnContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
}

.qtyCtaBtnUomContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
}

.qtyCtaBtnUomContainerList {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
}

.rootContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  flex-direction: column;
  &.loading {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(white, 0.8);
    }
  }
}

.errorContainer {
  animation: errorContainer 2.5s $main-timing-function forwards;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 0;
  padding: 10px;
  background: $color-error;
  border-radius: 5px;
  box-shadow:
    0 1.1px 2.2px rgba($color-error, 0.02),
    0 2.7px 5.3px rgba($color-error, 0.028),
    0 5px 10px rgba($color-error, 0.035),
    0 8.9px 17.9px rgba($color-error, 0.042),
    0 16.7px 33.4px rgba($color-error, 0.05),
    0 40px 80px rgba($color-error, 0.07);

  &:after {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: $color-error transparent transparent transparent;
  }
}

.errorText {
  font-size: 14px;
  color: white;
}

.loadingDotsWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.qtyAdjustmentButtonsContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  align-items: center;
  padding-right: 8px;
}

.qtyControlWrapper {
  display: flex;
  flex: 1 2 auto;
  align-items: center;
  background: white;
  border: 1px solid #babcbe;
  border-radius: 4px;
  width: 38px;
  height: 32px;
}

.qtyWrapper {
  display: flex;
  flex: 1 2 auto;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 1px solid $tundora;
  border-radius: 4px 0 0 4px;
}

.qtyContainer {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

@media #{$md-up} {
  .qtyControlWrapper {
    width: 68px;
    height: 40px;
  }

  .qtyContainer {
    margin-left: 0;
  }

  .root {
    margin-top: 32px;
  }

  .qtyAndCtaBtnContainer {
    gap: 16px;
  }

  .dprlContainer {
    gap: 8px;
  }
}
