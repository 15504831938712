/* 
* COLORS 
* color names is generate through http://chir.ag/projects/name-that-color/
*/
/*
 * MEDIA VARIABLES
 *
 * ** WARNING **
 *
 * If changing values, apply changes to CssConfig.js as well
 *
 */
/*
* GRID 
*/
/*
* BLOCK
*/
/* 
* TYPOGRAPHY 
*/
/* 
* ANIMATION 
*/
/* 
* Map of z-indexes. Should not contain any negative or zero values.
*/
/*
* Various settings, refactor if too long
*/
/* product page */
/* header */
/*checkout*/
/*spare parts cart modal*/
/**
* Override styles from FS Lightbox
*/
.container {
  padding: 48px 0px; }
  .container :global(.fslightbox-toolbar-button[title='Enter fullscreen']) {
    display: none; }
  .container :global(.fslightbox-toolbar-button[title='Close']) {
    background: #ffffff; }
    .container :global(.fslightbox-toolbar-button[title='Close']) svg path {
      fill: #00153d; }
  .container :global(.fslightbox-container) {
    background: #f8f9f9; }
  .container :global(.fslightbox-slide-btn-container):hover svg path {
    fill: #00153d; }
  .container :global(.fslightbox-slide-btn) {
    background: #ffffff; }
    .container :global(.fslightbox-slide-btn) svg path {
      fill: #00153d; }
  .container :global(.fslightbox-slide-number-container) {
    position: fixed;
    z-index: 3;
    bottom: 24px;
    left: 50%;
    height: auto;
    background: #ffffff;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Helvetica Neue LT W05_55 Roman", "Helvetica Neue LT W05_65 Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica", Oxygen-Sans, Ubuntu, Cantarell sans-serif;
    color: #202124; }
  .container :global(.fslightbox-slash) {
    background: #202124; }

.gallery {
  display: grid;
  gap: 8px; }

.galleryOne {
  max-height: 500px;
  grid-template-rows: 1fr;
  grid-template-areas: 'first'; }

.galleryTwo {
  max-height: 500px;
  grid-template-rows: 1fr;
  grid-template-areas: 'first second second'; }

.galleryThree {
  max-height: 500px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'first third third' 'second third third'; }

.galleryFour {
  max-height: 375px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'first second fourth fourth' 'third second fourth fourth'; }

.galleryFive {
  max-height: 750px;
  grid-template-rows: 2fr 1fr;
  grid-template-areas: 'first second second' 'third fourth fifth'; }

.image {
  height: 100%;
  position: relative;
  z-index: 1;
  transition: transform 0.6s ease-in-out; }
  .image:hover {
    cursor: pointer;
    transform: scale(1.05); }

.imageOverride {
  width: 100%;
  max-width: 100%;
  object-fit: cover; }

.item {
  overflow: hidden; }

.item1 {
  grid-area: first; }

.item2 {
  grid-area: second; }

.item3 {
  grid-area: third; }

.item4 {
  grid-area: fourth; }

.item5 {
  grid-area: fifth; }

.viewAll {
  margin-top: 24px; }

.buttonIcon {
  margin-right: 8px; }

.noVerticalPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

@media only screen and (min-width: 768px) {
  .container {
    padding: 80px 0px; } }
