@import '../../../Styles/settings';
@keyframes svgAnimationIn {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(6px);
  }
}

@keyframes svgAnimationOut {
  0% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes svgAnimationInFlipped {
  0% {
    transform: translateX(0) scaleX(-1);
  }
  100% {
    transform: translateX(6px) scaleX(-1);
  }
}

@keyframes svgAnimationOutFlipped {
  0% {
    transform: translateX(6px) scaleX(-1);
  }
  100% {
    transform: translateX(0) scaleX(-1);
  }
}

.btn {
  @include btn;
  transition: background-color 0.3s $main-timing-function,
    box-shadow 0.3s $main-timing-function, color 0.3s $main-timing-function;
  position: relative;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
  &.rightBorderRadius {
    border-radius: 0 4px 4px 0;
  }
  &.loading {
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
    color: transparent;
    box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
  &[disabled] {
    opacity: 0.6;
    cursor: default;
  }
}

.smallBtn {
  padding: 8px 24px;
}

.smallerBtn {
  padding: 8px;
}

.thinBtn {
  padding: 12px 16px;
}

.bigBtn {
  padding: 12px 32px;
}

.brand {
  background-color: $color-brand;
}

.primary {
  background-color: $color-primary-1;
}

.secondary {
  background-color: $color-primary-2;
}

.third {
  background-color: $color-primary-3;
}

.cta {
  background-color: $color-cta;
}

.ctaLight {
  background-color: $light-blue;
}

.danger {
  background-color: $color-error;
}

.ctaWhite {
  background-color: $color-primary-bg;
  color: $color-brand;
}

.ctaWhiteBorder {
  background-color: $color-primary-bg;
  color: $color-brand;
  border: 1px solid $midnight-blue;
}

.lightBrand {
  background-color: $color-cta-light;
}

.generic {
  background: transparent;
  color: $color-cta;
  transition: box-shadow 0.3s $main-timing-function;
}

.tertiary {
  background-color: $casablanca;
  color: $white;
}

.block {
  width: 100%;
}

.svgIcon {
  margin-right: 10px;
  &.flipArrow {
    transform: scaleX(-1);
  }
}

.fullWidth {
  width: 100%;
}

.disabled {
  @include disabledButton;
}

@include media(null, $screen-sm-max) {
  .blockMobile {
    width: 100%;
  }
}

@media #{$md-up} {
  .btn {
    .svgIcon {
      animation: svgAnimationOut $main-timing-function 0.3s;

      &.flipArrow {
        animation: svgAnimationOutFlipped $main-timing-function 0.3s;
      }
      &:not(.flipArrow) {
        animation: svgAnimationOut $main-timing-function 0.3s;
      }
    }
    &:hover {
      .svgIcon {
        &.flipArrow {
          animation: svgAnimationInFlipped $main-timing-function 0.3s;
          animation-fill-mode: forwards;
        }
        &:not(.flipArrow) {
          animation: svgAnimationIn $main-timing-function 0.3s;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  .brand {
    &:hover {
      background-color: darken($color-brand, 6%);
    }
  }

  .cta {
    &:hover {
      background-color: darken($color-cta, 8%);
    }
  }

  .primary {
    &:hover {
      background-color: darken($color-primary-1, 8%);
    }
  }

  .secondary {
    &:hover {
      background-color: darken($color-primary-2, 8%);
    }
  }

  .third {
    &:hover {
      background-color: darken($color-primary-3, 8%);
    }
  }

  .danger {
    &:hover {
      background-color: darken($color-error, 8%);
    }
  }

  .ctaWhite {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $midnight-blue;
    }
  }

  .ctaWhiteBorder {
    &:hover {
      background-color: darken($color-primary-bg, 8%);
    }
  }

  .lightBrand {
    &:hover {
      background-color: darken($color-cta-light, 8%);
    }
  }

  .generic {
    padding: 10px 15px;
    box-shadow: inset 0 0 0 0 $color-cta;
    &:hover {
      box-shadow: inset 0px -3px 0px 0px $color-cta;
    }
  }
}
