@import '../../Styles/settings';

.textContainer {
    width: 100%;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
  }
  
.textWrapper {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }