@import '../../Styles/settings';

.loadingDotsWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 15px;
  z-index: map-get($zIndexes, 'mobileMenuLoadingDotsWrapper');
  background: white;
}

@media #{$md-up} {
  .loadingDotsWrapper {
    top: 120px;
    left: 0;
    padding-left: 85px;
    right:0
  }
}