@import '../../../Styles/settings';

.btn {
  transition: background-color 0.3s $main-timing-function;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loadingDotsWrapper {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.size-xxxs {
  width: 16px;
  height: 16px;
  padding: 0;
}

.size-xxs {
  width: 24px;
  height: 24px;
  padding: 4px;
}

.size-xs {
  width: 32px;
  height: 32px;
  padding: 6px;
}

.size-s {
  width: 40px;
  height: 40px;
  padding: 9px;
}

.size-m {
  width: 48px;
  height: 48px;
  padding: 11px;
}

.size-l {
  width: 56px;
  height: 56px;
  padding: 13px;
}

.image-size-xs {
  background-size: 14px;
  &.noBg {
    background-size: 18px;
  }
}

.image-size-s {
  background-size: 16px;
  &.noBg {
    background-size: 20px;
  }
}

.image-size-m {
  background-size: 20px;
  &.noBg {
    background-size: 24px;
  }
}

.image-size-l {
  background-size: 24px;
  &.noBg {
    background-size: 28px;
  }
}

.round {
  border-radius: 50%;
}

.cover {
  width: 100%;
}

.opacity {
  opacity: 0.5;
}

.brand {
  background-color: $color-brand;
}

.primary {
  background-color: $color-primary-1;
}

.secondary {
  background-color: $color-primary-2;
}

.third {
  background-color: $color-primary-3;
}

.transparent {
  background-color: transparent;
}

.cta {
  background-color: $color-cta;
}

.step {
  background-color: $mercury;
}

.error {
  background-color: $color-error;
}

.white {
  background-color: #fff;
}

.gray {
  background-color: $light-gray;
}

.disabled {
  opacity: 0.6;
  cursor: default;
}

@media #{$md-up} {
  .brand:not(.disabled) {
    &:hover {
      background-color: darken($color-brand, 8%);
    }
  }

  .primary:not(.disabled) {
    &:hover {
      background-color: darken($color-primary-1, 8%);
    }
  }

  .secondary:not(.disabled) {
    &:hover {
      background-color: darken($color-primary-2, 8%);
    }
  }

  .third:not(.disabled) {
    &:hover {
      background-color: darken($color-primary-3, 8%);
    }
  }

  .step:not(.disabled) {
    &:hover {
      background-color: darken($mercury, 8%);
    }
  }

  .white:not(.disabled) {
    &:hover {
      background-color: darken(white, 8%);
    }
  }
}
