@import '../../Styles/settings';

@keyframes modalOpen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalSlider {
  from {
    top: -100vh;
  }
  to {
    top: 0;
  }
}

@keyframes slideToRight {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}

.root {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  animation: modalOpen 0.3s $main-timing-function;
  transition: opacity 0.3s $main-timing-function;
  opacity: 1;
  z-index: map-get($zIndexes, 'modalNoHeader');
  pointer-events: none;

  &.isReady {
    pointer-events: all;
  }

  &.modalClosing {
    pointer-events: auto;
    opacity: 0;
  }

  &.opaque {
    background: $color-modal-bg;
  }

  &.transparent {
    background-color: $color-modal-bg-transparent;
  }

  &.showHeader {
    max-height: calc(100vh - 56px);
    top: unset;
    bottom: 0;
    z-index: map-get($map: $zIndexes, $key: 'modal');
  }

  &.transparentDark {
    background: $color-modal-bg-transparent-dark;
  }
}

.slider {
  position: relative;
  width: 100%;

  &.top {
    animation: modalSlider 0.3s $main-timing-function;
    transition: top 0.3s $main-timing-function;
    top: 0;
  }

  &.modalClosing-top {
    pointer-events: auto;
    top: -100vh;
  }

  &.right {
    animation: slideToRight 0.3s $main-timing-function;
    transition: right 0.3s $main-timing-function;
    right: 0;
  }

  &.modalClosing-right {
    pointer-events: auto;
    right: -100vw;
  }
}

@media #{$md-up} {
  .root {
    &.showHeader {
      max-height: calc(100vh - #{$standard-header-height-md-up});
    }
  }
}
