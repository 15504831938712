@import '../../../Styles/settings';


.uomBtn {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: $white;
  border: $white;
  padding: 0px;
}

.btnContentUpperText{
  @include HelveticaMedium;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0px;
  color: $near-black;
  display: flex;
  align-items: center;
  svg{
    margin-right: 8px;
  }
  &.smallText{
    font-size: 12px;
  }
}

.btnContentLowerText{
  text-align: left;
  font-size: 10px;
  color: $darkest-gray;
  line-height: 16px;
  letter-spacing: 0;
}

.btnText {
  color: $midnight-blue;
  font-size: 12px;
  line-height: 16px;
  margin: auto;
}

.isSelected {
  background-color: white;

  .btnText {
    color: $midnight-blue;
  }
}

@media #{$md-up} {
  .btnContentUpperText{
    font-size: 16px;
    line-height: 20px;
  }

  .btnContentLowerText{
    font-size: 12px;  
  }
}