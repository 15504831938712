@import '../../Styles/settings';

.grid {
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  &.smallGap {
    column-gap: 8px;
    row-gap: 8px;
  }
  &.mediumGap {
    column-gap: 12px;
    row-gap: 12px;
  }
}

.noColumnGap {
  column-gap: 0px;
}

.noRowGap {
  row-gap: 0px;
}

@media print {
  .grid {
    display: block;
  }
}

@media #{$sm-up} {
  .grid {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    column-gap: 16px;
    row-gap: 16px;
  }
}

@media #{$md-up} {
  .grid {
    column-gap: 24px;
    row-gap: 24px;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    &.smallGap {
      column-gap: 24px;
      row-gap: 24px;
    }
    &.mediumGap {
      column-gap: 12px;
      row-gap: 12px;
    }
  }

  .noColumnGap {
    column-gap: 0px;
  }

  .noRowGap {
    row-gap: 0px;
  }

  :global([data-style='cards-as-list']) {
    .grid {
      column-gap: 8px;
      row-gap: 8px;
    }
  }
}
