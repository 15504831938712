@import '../../../Styles/settings';

.btn {
  @include btn;
  display: flex;
  white-space: nowrap;
  margin-left: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: $color-brand;
  transition: background-color 0.3s $main-timing-function;
  border-radius: 4px;
  margin-top: auto;
  line-height: 16px;
  &:hover {
    background-color: $darkmidnight_blue;
  }
}
.ctaBtnWrapper {
  width: 100%;
}

.svg {
  margin-left: 4px;
  fill: currentColor;
  height: 17px;
  width: 16px;
}

.loadingDotsWrapper {
  margin-top: 12px;
}

.princeInfoWrapperListView {
  width: 100%;
}

.priceInfoWrapperGrid {
  background: white;
  width: 100%;
  margin-top: auto;
  @include styleComponent('style_id_productCardBtn_addToCart') {
    flex: 100 0 70px;
    min-width: 70px;
    height: auto;
    min-height: 40px;
  }

  &.hide {
    display: none;
  }
}

.addToCartWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  align-items: flex-start;
}

.priceWrapper {
  margin-bottom: 5px;
}
.priceUnitWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.price {
  font-weight: 500;
}
.unit {
  font-weight: 300;
}
.spacer {
  width: 10px;
}

.priceDetails {
  font-size: 12px;
  font-weight: 300;
  color: $text-color-tone-down;
  display: inline-block;
  margin-top: 5px;
}

@include media(600px) {
  .priceInfoWrapperGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .hide {
    display: none;
  }
}

@media #{$sm-up} {
}

@media #{$md-up} {
  .priceInfoWrapperGrid {
    @include styleComponent('style_id_productCardBtn_addToCart') {
      margin-left: 1px;
    }
  }

  .ctaBtnWrapper {
    width: 100%;
  }

  .btn {
    padding: 12px 16px;
    font-size: 12px;
  }

  :global([data-style='cards-as-list']) {
    .disabled {
      display: none;
    }

    .priceInfoWrapperGrid {
      flex: 0 1 auto;
      margin: 0 0 0 auto;
      max-width: 200px;
      width: auto;
      justify-content: flex-end;
      padding: 0;
      @include styleComponent('style_id_productCardBtn_addToCart') {
        width: calc(100% - 122px);
      }
    }
    .priceWrapper {
      margin-bottom: 15px;
    }

    .addToCartWrapper {
      flex: 0 2 100%;
    }
  }
}

@include media($content-container-width) {
  .priceInfoWrapperGrid {
    padding: 0;
  }
}

@media print {
  .btn,
  .addToCartWrapper {
    display: none;
  }

  .priceInfoWrapperGrid {
    width: auto;
    margin: 0;
  }
}
