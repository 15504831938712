@import '../../../Styles/settings';

.source {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.root {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    padding-top: 51.95%;
    width: 100%;
  }
}

.rootYoutube{
  background: black;
}

