@import '../../Styles/variables';
@import '../../Styles/settings';

.category-block {
  --block-size: 21vw;
  --link-size: 12px;
  --link-line-height: 14px;
  --link-margin: 8px;

  @media #{$sm-up} {
    --block-size: 12vw;
  }

  @media #{$md-up} {
    --block-size: 168px;
    --link-size: 18px;
    --link-margin: 16px;
    --link-line-height: 24px;
  }

  text-align: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--block-size);

  &__image-wrapper {
    img {
      width: 100%;
      height: var(--block-size);
      border-radius: 50%;
      background-color: rgba(167, 168, 169, 0.1);
      object-fit: cover;
    }
  }

  &__link {
    display: block;
    margin-top: var(--link-margin);
    color: $color-brand;
    text-decoration: none;
    font-size: var(--link-size);
    line-height: var(--link-line-height);
    @include HelveticaMedium;
  }
}

@keyframes svgAnimation {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0px);
  }
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.imgWrapper {
  position: relative;
  text-decoration: none;
  &:after {
    display: block;
    content: '';
    padding-top: 62.5%;
    width: 100%;
  }
}

.imgWrapperWhite {
  background: white;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.h3 {
  margin-bottom: 0;
}

.h3Grow {
  flex-grow: 1;
}

.linkContainer {
  margin-top: 1px;
  padding: 10px 15px;
  background: #0c2b63;
  flex-grow: 1;
}

.headingLink {
  padding: 15px;
  justify-content: center;
  display: flex;
}

.subLink {
  padding: 5px 0px;
  display: inline-flex;
  .arrowRightIcon {
    display: none;
  }
}

.headingLink,
.subLink {
  align-items: center;
  text-decoration: none;
}

.headingText {
  @include HelveticaMedium;
  max-width: calc(100% - 14px);
  padding-right: 10px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.arrowRightIcon {
  fill: currentColor;
  width: 14px;
  height: 14px;
}

.subLinkText {
  transition: padding-left 0.3s $main-timing-function;
  font-size: 16px;
  font-weight: 300;
  position: relative;
}

@media #{$md-up} {
  .linkContainer {
    margin-top: 0;
  }

  .headingLink,
  .linkContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .headingLink {
    &:hover {
      svg {
        animation: svgAnimation 0.3s $main-timing-function;
      }
    }
  }

  .subLinkText {
    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 10px;
      right: 0;
      height: 1px;
      transition: background-color 0.3s $main-timing-function;
    }
  }
  .subLink {
    .arrowRightIcon {
      max-width: 0;
      display: block;
      transition: max-width 0.3s $main-timing-function;
    }

    &:hover {
      .arrowRightIcon {
        flex: 0 0 14px;
        flex-grow: 1;
        max-width: 14px;
      }
      .subLinkText {
        padding-left: 10px;
        &:after {
          background: currentColor;
        }
      }
    }
  }
}
