.colorBlendOption {
    display: flex;
    justify-content: space-between;

    height: 44px;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    &:hover {
        background-color: #f1f3f4;
    }
    .dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.colorBlendDropdown {
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 4px;
    border: solid 1px #f1f3f4;
    z-index: 1;
}
