@import '../../Styles/settings';

.wrapper {
  padding: $verticleBlockPaddingXS 0;
  max-width: 770px;
  margin: auto;
}

.bodyText {
  width: 100%;
}

.zooja {
  @include zoojaFont;
}

.linkWrapper {
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.linkContainer {
  margin-top: 20px;
}

@media #{$md-up} {
  .wrapper {
    padding: $verticleBlockPaddingMD 0;
  }
}
