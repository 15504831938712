@import '../../Styles/settings';

.logo {
  width: 100px;
  height: auto;
}

.xhtmlCompanyDesc {
  font-size: 12px;
  padding: 20px 0px 0;
  text-align: left;
  p {
    margin-bottom: 32px;
  }
}

.logoContainer {  
  img {
    margin: unset;
  }
}
  
.onlyDesktop {
  display: none;
}

@media #{$md-up} {
  .leftAlign {
    .logo {
      margin-left: 0;
    }
  }

  .onlyMobile {
    display: none;
  }
}
