@import '../../../Styles/settings';
.root {
  background-color: $bona-light-blue;
  width: 100%;
  padding: 16px;
  left: 50%;
  transform: translate(-50%, 256px);
  position: absolute;
  border-radius: 8px;
  max-width: 1128px;
  overflow-y: hidden;
}

.closeIconContainer {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  svg {
    cursor: pointer;
  }
}
