@import '../../../Styles/settings';

.priceContainer {
  &.listview{
    &.homeowner{
      align-self: flex-end;
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }

  &:not(.listview){
    margin-top: auto;
  }
}

.price {
  @include HelveticaMedium;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: $midnight-blue;
}

@media #{$md-up} {
  .price {
    font-size: 18px;
    line-height: 24px;
  }
}

:global([data-style='cards-as-list']) {
  .price {
    text-align: right;
  }
}
