@import '../../Styles/settings';

.root {
  flex: 0 0 100%;
}

.iframe {
  width: 100%;
  height: 100%;
}

.borderLess {
  border: 0;
}

.span {
  display: inline-block;
  height: 1px;
  width: 1px;
}

.cookieInfo {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
  background: white;
}

.cookieInfoSliderPadding {
  padding-left: 45px;
  padding-right: 45px;
}

.iconWrapper {
  position: relative;
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }

  &:before {
    background: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' style='width: 100%25; height: 100%25;'%3E%3Cline x1='0' y1='100%25' x2='100%25' y2='0' style='stroke:rgb(178, 36, 62);stroke-width:2'/%3E%3C/svg%3E")
      no-repeat;
  }

  &:after {
    background: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' style='width: 100%25; height: 100%25;'%3E%3Cline x1='100%25' y1='100%25' x2='0' y2='0' style='stroke:rgb(178, 36, 62);stroke-width:2'/%3E%3C/svg%3E")
      no-repeat;
  }

  circle {
    fill: $color-brand;
  }

  path {
    fill: white;
  }
}
.cookieInfoTextWrapper {
  flex: 0 0 100%;
  max-width: 100%;
}

.cookieInfoBtnWrapper {
  flex: 0 0 100%;
  justify-content: center;
  display: flex;
}
