@import "../../Styles/settings";

.root {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  flex-direction: column;
}

.categoryText {
  color: $dark-gray;
  font-size: 10px;
  text-transform: uppercase;
}

.whiteText {
  color: white;
}
.blackText {
  color: black;
}
.zooja {
  @include zoojaFont;
}

.imgLinkModifiedForCategoryBlock {
  display: block;
}

.iframe {
  height: 100%;
  width: 100%;
}

.textContainerNoHeading {
  padding-top: 15px;
}

.linkEditPlaceHolder {
  display: inline-block;
  height: 20px;
  width: 50px;
}

.imgContainer {
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;

  &.roundedCorners {
    border-radius: 8px;
  }

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.textContainer {
  width: 100%;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  &.bottomBorderRadius {
    border-radius: 0px 0px 8px 8px;
  }
}
.textWrapper {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.header {
  margin-top: 0;
  font-size: 19px;
  &.zooja {
    font-size: 2.5em;
  }
}

:global(.slider) {
  .root {
    padding-bottom: 60px;
  }
}

.lead {
  font-weight: normal;
  font-size: 1.1rem;
}

.linkContainer {
  margin-top: 15px;
  display: flex;
  &.rightLink {
    justify-content: flex-end;
  }
}

@include media(500px, null) {
  .imgContainer {
    height: 250px;
  }

  .header {
    &.zooja {
      font-size: 2.5em;
    }
    @include setRemToProp(font-size, $base-font-size, 23);
  }
}

@media #{$sm-up} {
  .root {
    flex-direction: row;
  }

  .nowrap {
    flex-wrap: nowrap;
  }

  .column {
    flex-direction: column;
    .imgContainer {
      flex-grow: 0;
    }
  }

  .textWrapper {
    padding-bottom: 0px;
  }

  .imgContainer {
    height: auto;
    /*
    * using "important" because when in edit mode epi's [data-epi-property-name] injected class
    * will override the rule.
    */
    min-height: 345px !important;

    &.text-container-size-is-1\/1 {
      width: 100%;
      height: 345px;
    }
    &.text-container-size-is-1\/2 {
      width: 50%;
    }
    &.text-container-size-is-1\/3 {
      width: 66.66666%;
    }
    &.text-container-size-is-2\/3 {
      width: 33.33333%;
    }
  }

  .imgContainer {
    &.right {
      order: 1;
    }
  }

  .textContainer {
    flex-grow: 1;
    padding-top: 20px;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    &.leftBorderRadius {
      border-radius: 8px 0px 0px 8px;
    }

    &.rightBorderRadius {
      border-radius: 0px 8px 8px 0px;
    }
  }
}

@media #{$md-up} {
  .header {
    &.zooja {
      font-size: 2.5em;
    }
    @include setRemToProp(font-size, $base-font-size-md, 26);
  }

  .textContainer {
    padding-top: 25px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
